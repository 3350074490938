/* GENERAL STYLES
---------------------------------------------*/
body {
  margin: 0;
  padding: 0;
  background: #fff;
  color: #000;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 93.75%;
  line-height: 1.5625;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}
@media only screen and (min-width: 700px ) {
  body {
    font-size: 100%;
  }
}
@media only screen and (min-width: 1025px ) {
  body {
    font-size: 112.5%;
    line-height: 1.7;
  }
}
a {
  color: #607631;
  text-decoration: underline;
  outline: none;
  transition: all .2s ease;
}
a:hover,
a:focus {
  color: #00759A;
  text-decoration: none;
}
a img {
  border: none;
}
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5,
.PageTitleHeader,
.PageSubTitleHeader {
  font-weight: 500;
  padding: 0;
  color: #000;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}
h1.divider,
h2.divider,
h3.divider,
h4.divider,
h5.divider,
.h1.divider,
.h2.divider,
.h3.divider,
.h4.divider,
.h5.divider,
.PageTitleHeader.divider,
.PageSubTitleHeader.divider {
  border-top: 3px solid #607631;
  color: #607631;
  padding-top: 0.75rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  padding-right: 2rem;
}
h1.green,
h2.green,
h3.green,
h4.green,
h5.green,
.h1.green,
.h2.green,
.h3.green,
.h4.green,
.h5.green,
.PageTitleHeader.green,
.PageSubTitleHeader.green {
  color: #607631 !important;
}
h1,
.h1,
.PageTitleHeader {
  font-size: 1.875rem;
  line-height: 1;
  font-weight: 500;
}
@media only screen and (min-width: 700px ) {
  h1,
  .h1,
  .PageTitleHeader {
    font-size: 2.5rem;
  }
}
@media only screen and (min-width: 1025px ) {
  h1,
  .h1,
  .PageTitleHeader {
    font-size: 4rem;
  }
}
h2,
.h2,
.PageSubTitleHeader {
  font-size: 1.625rem;
  line-height: 1.25;
  font-weight: 500;
}
@media only screen and (min-width: 1025px ) {
  h2,
  .h2,
  .PageSubTitleHeader {
    font-size: 1.75rem;
  }
}
h2.divider,
.h2.divider,
.PageSubTitleHeader.divider {
  font-size: 1.625rem;
  line-height: 1.3;
  font-weight: 400;
  text-transform: none;
}
@media only screen and (min-width: 1025px ) {
  h2.divider,
  .h2.divider,
  .PageSubTitleHeader.divider {
    font-size: 1.75rem;
    margin-top: 3.5rem;
  }
}
h3,
.h3 {
  font-size: 1.125rem;
  line-height: 1.25;
  color: #000;
}
@media only screen and (min-width: 1025px ) {
  h3,
  .h3 {
    font-size: 1.25rem;
  }
}
h4,
.h4 {
  font-size: 1.625rem;
  line-height: 1.25;
  color: #607631;
  text-transform: none;
  font-weight: 400;
}
@media only screen and (min-width: 1025px ) {
  h4,
  .h4 {
    font-size: 1.75rem;
  }
}
h5,
.h5 {
  font-size: 1.375rem;
  line-height: 1.25;
  text-transform: uppercase;
  font-weight: 400;
}
p {
  margin: 0.5em 0 1.5em;
  padding: 0;
}
p.large {
  font-size: 1.25em;
  font-weight: 300;
  margin-bottom: 1.5em;
}
@media only screen and (min-width: 700px ) {
  p.large {
    font-size: 1.56em;
    font-weight: 300;
  }
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
p:first-child {
  margin-top: 0;
}
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child {
  margin-bottom: 0;
}
#PageTitle,
.PageTitleHeader {
  display: none;
}
.textalignright {
  text-align: right;
}
.textalignleft {
  text-align: left;
}
hr {
  height: 1px;
  line-height: 1px;
  margin: 2em 0;
  clear: both;
  border: none;
  background: #D8D8D8;
  color: #D8D8D8;
}
img.left {
  float: left;
  margin: 0 2rem 10px 0;
}
img.right {
  float: right;
  margin: 0 0 10px 2rem;
}
img.outline {
  border: 4px solid #666;
}
.clearing {
  clear: both;
  height: 0;
  margin: 0;
  padding: 0;
  line-height: 0;
  margin-top: -7px;
}
.clearBoth {
  clear: both;
}
.clearRight {
  clear: right;
}
.clearLeft {
  clear: left;
}
.invisibleButAccessible {
  display: none;
}
.idbmsBreadcrumbSeparator {
  color: #607631 !important;
}
.expand img {
  background-color: #607631 !important;
}
.expand {
  background-color: #607631 !important;
  display: inline-block;
  width: 18px;
}
.Error,
.error {
  width: auto!important;
}
img[src="/images/icons/v2/common/brick_edit.gif"],
img[src="/images/icons/v2/common/delete.gif"],
img[src="/images/icons/v2/common/arrow_out.gif"],
img[src="/images/e.gif"],
img[src="/images/spacer.gif"],
img[src="/images/icons/v2/bomb.png"] {
  border: 0!important;
  padding: 0!important;
  margin: 0!important;
  float: none!important;
  width: 16px!important;
  height: 16px !important;
}
.pageWrap {
  position: relative;
}
.pageWrap img {
  max-width: 100%;
  height: auto;
}
.pageWrap img[src="/images/spacer.gif"] {
  display: none;
}
.pageWrap img * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
}
.showColor.imodBlue {
  overflow: hidden;
}
b,
strong {
  font-weight: bold;
}
.EventsHeader td:nth-child(2) {
  width: 18px !important;
  height: 18px !important;
}
.EventsHeader span.expand img {
  width: 18px !important;
  height: 18px !important;
}
::selection {
  background: #00759A;
  color: #fff;
}
/* Safari 3.1+, Opera 9.5+, Chrome 2+ */
::-moz-selection {
  background: #00759A;
  color: #fff;
}
/* Mozilla Firefox */
/* Flexbox Equal heights */
.flexbox .equal-height {
  display: flex;
  flex-direction: row;
}
.flexbox .equal-height-item {
  display: inherit;
  flex-direction: column;
}
.flexbox .equal-height-item-inner {
  flex: 1 0 auto;
}
/* Stack items on mobile */
@media only screen and (max-width:  1024px ) {
  .flexbox .equal-height {
    flex-direction: column;
  }
}
@media only screen and (min-width: 700px) and (max-width:  1024px ) {
  .container {
    width: 100%;
  }
}
/*button default*/
.button,
a.button,
input[type="submit"],
input[type="button"],
input[type="reset"] {
  background: #fff;
  font-size: 1.0625rem;
  line-height: normal;
  color: #000;
  border: 3px solid #CFD82D;
  padding: 10px 25px;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 1rem;
  transition: all .2s ease;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.button + .button,
.button + a.button,
.button + input[type="submit"],
.button + input[type="button"],
.button + input[type="reset"],
a.button + .button,
a.button + a.button,
a.button + input[type="submit"],
a.button + input[type="button"],
a.button + input[type="reset"],
input[type="submit"] + .button,
input[type="submit"] + a.button,
input[type="submit"] + input[type="submit"],
input[type="submit"] + input[type="button"],
input[type="submit"] + input[type="reset"],
input[type="button"] + .button,
input[type="button"] + a.button,
input[type="button"] + input[type="submit"],
input[type="button"] + input[type="button"],
input[type="button"] + input[type="reset"],
input[type="reset"] + .button,
input[type="reset"] + a.button,
input[type="reset"] + input[type="submit"],
input[type="reset"] + input[type="button"],
input[type="reset"] + input[type="reset"] {
  margin-left: 1rem;
}
.button:after,
a.button:after,
input[type="submit"]:after,
input[type="button"]:after,
input[type="reset"]:after {
  content: "\f30b";
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
  transform: translateX(0);
  transition: transform ease-out 0.1s;
  display: inline-block;
  margin-left: 0.5rem;
}
.button:hover,
a.button:hover,
input[type="submit"]:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
.button:focus,
a.button:focus,
input[type="submit"]:focus,
input[type="button"]:focus,
input[type="reset"]:focus {
  background-color: #CFD82D;
}
.button:hover:after,
a.button:hover:after,
input[type="submit"]:hover:after,
input[type="button"]:hover:after,
input[type="reset"]:hover:after,
.button:focus:after,
a.button:focus:after,
input[type="submit"]:focus:after,
input[type="button"]:focus:after,
input[type="reset"]:focus:after {
  transform: translateX(4px);
}
.button:focus,
a.button:focus,
input[type="submit"]:focus,
input[type="button"]:focus,
input[type="reset"]:focus {
  outline: 1px solid rgba(0, 117, 154, 0.75);
}
.button.reverse,
a.button.reverse,
input[type="submit"].reverse,
input[type="button"].reverse,
input[type="reset"].reverse {
  background: #CFD82D;
}
.button.reverse:hover,
a.button.reverse:hover,
input[type="submit"].reverse:hover,
input[type="button"].reverse:hover,
input[type="reset"].reverse:hover,
.button.reverse:focus,
a.button.reverse:focus,
input[type="submit"].reverse:focus,
input[type="button"].reverse:focus,
input[type="reset"].reverse:focus {
  background-color: #000;
  color: #fff;
  border-color: #000;
}
.columnList-four {
  column-count: 1;
}
@media only screen and (min-width: 700px) and (max-width:  1024px ) {
  .columnList-four {
    column-count: 2;
  }
}
@media only screen and (min-width: 1025px ) {
  .columnList-four {
    column-count: 4;
  }
}
.columnList-three {
  column-count: 1;
}
@media only screen and (min-width: 700px ) {
  .columnList-three {
    column-count: 3;
  }
}
.columnList-two {
  column-count: 1;
}
@media only screen and (min-width: 700px ) {
  .columnList-two {
    column-count: 2;
  }
}
.columnList-four,
.columnList-three,
.columnList-two {
  padding-left: 25px;
}
@media only screen and (min-width: 700px ) {
  .columnList-four li,
  .columnList-three li,
  .columnList-two li {
    padding-right: 30px;
  }
}
blockquote {
  color: #607631;
  font-weight: 500;
  font-style: italic;
  font-size: 1.5rem;
  line-height: 1.5;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
@media only screen and (min-width: 1025px ) {
  blockquote {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}
blockquote:first-child {
  margin-top: 0;
}
#main .snippetrow {
  width: auto;
  margin-left: -15px;
  margin-right: -15px;
}
#main .snippetrow > * {
  padding-left: 15px;
  padding-right: 15px;
}
#main table.table {
  border-bottom: 0.24rem solid #02759A;
  color: #1d252d;
  margin: 3.75rem 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  font-size: 1rem;
  line-height: normal;
}
#main table.table caption {
  font-size: 0.75rem;
}
#main table.table thead th {
  padding: 1.25rem;
  background-color: #02759A;
  color: #ffffff;
  border: none;
  font-weight: bold;
}
#main table.table tbody tr {
  background-color: #eeefef;
  border-top: 0.0625rem dashed #02759A;
}
#main table.table tbody tr:first-child {
  border: none;
}
#main table.table tbody tr:nth-child(2n+1) {
  background-color: #fafafa;
}
#main table.table tbody tr td {
  padding: 0.625rem 1.25rem;
  border: none;
}
#main .table-responsive {
  margin: 3.75rem 0;
}
#main .table-responsive table.table {
  margin: 0;
}
/* UTILITY BAR ELEMENTS
---------------------------------------------*/
.utilityBar {
  float: left;
  width: 100%;
  background: #000;
  overflow: hidden;
}
.utilityBar .container-fluid {
  padding: 0;
}
.utilityLinks {
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
}
.utilityLinks ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
@media only screen and (min-width: 700px) and (max-width:  1024px ) {
  .utilityLinks ul {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.utilityLinks ul li {
  display: inline-block;
}
.utilityLinks ul li + li {
  border-left: 1px solid #fff;
}
.utilityLinks ul li a {
  display: block;
  color: #fff;
  text-decoration: none;
  min-width: 135px;
  padding: 0.5rem 1rem;
  text-align: center;
}
.utilityLinks ul li a:hover,
.utilityLinks ul li a:focus {
  text-decoration: underline;
  background: #555;
}
.utilityLinks ul li a:focus {
  outline: 2px solid #CFD82D;
  outline-offset: -3px;
}
.utilityLinks ul li.green {
  background: #607631;
}
/* HEAD ELEMENTS
---------------------------------------------*/
.structHead {
  width: 100%;
  background: #fff;
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 4;
  clear: both;
}
@media only screen and (min-width: 700px ) {
  .structHead {
    position: relative;
  }
}
.loggedin .structHead {
  position: relative;
}
.structHead #mobileHeaderWrap {
  clear: both;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.structHead .mainHeaderWrap {
  border-top: 10px solid #000;
  position: relative;
  z-index: 10;
  background: #fff;
}
@media only screen and (min-width: 700px ) {
  .structHead .mainHeaderWrap {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (min-width: 1025px ) {
  .structHead .mainHeaderWrap {
    padding-left: 0;
    padding-right: 0;
    border-top: none;
    padding-top: 2rem;
  }
}
.openMenu .structHead .mainHeaderWrap {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
}
@media only screen and (min-width: 1025px ) {
  .structHead .headerRight {
    display: flex;
    justify-content: flex-end;
  }
}
.structHead:after {
  background: #F4F4F4;
  content: "";
  position: fixed;
  top: 0;
  width: 100vh;
  height: 100vh;
  display: none;
  z-index: 0;
}
.openMenu .structHead {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.openMenu .structHead:after {
  display: block;
}
@media only screen and (min-width: 1025px ) {
  .headLogo {
    padding-bottom: 1.5rem;
  }
}
.headLogo a {
  display: block;
  outline-offset: 3px;
}
.headLogo a:focus {
  outline: solid 2px rgba(0, 117, 154, 0.75);
}
.headLogo img,
.headLogo svg {
  max-height: 38px;
}
@media only screen and (min-width: 700px ) {
  .headLogo img,
  .headLogo svg {
    max-height: 44px;
  }
}
@media only screen and (min-width: 1025px ) {
  .headLogo img,
  .headLogo svg {
    max-height: 65px;
  }
}
.menuMobilBtnWrap div.innerWrap {
  display: flex;
  justify-content: flex-end;
  height: 75px;
  align-items: center;
}
@media only screen and (min-width: 1025px ) {
  .menuMobilBtnWrap div.innerWrap {
    height: 100%;
    justify-content: flex-start;
    margin-top: -1rem;
  }
}
.menuMobilBtnWrap #searchToggle,
.menuMobilBtnWrap #searchToggleLg {
  background: transparent;
  font-size: 1rem;
  line-height: 1;
  padding: 0;
  margin-right: 0px;
  height: 45px;
  width: 40px;
  border: none;
  transition: all .2s ease;
  outline-offset: -2px;
}
.menuMobilBtnWrap #searchToggle .closeSearch,
.menuMobilBtnWrap #searchToggleLg .closeSearch {
  display: none;
}
.menuMobilBtnWrap #searchToggle:hover,
.menuMobilBtnWrap #searchToggleLg:hover,
.menuMobilBtnWrap #searchToggle:focus,
.menuMobilBtnWrap #searchToggleLg:focus {
  color: #fff;
  background: #607631;
}
.menuMobilBtnWrap #searchToggle:focus,
.menuMobilBtnWrap #searchToggleLg:focus {
  outline: 2px solid rgba(0, 117, 154, 0.75);
}
.menuMobilBtnWrap #searchToggle.open .openSearch,
.menuMobilBtnWrap #searchToggleLg.open .openSearch {
  display: none;
}
.menuMobilBtnWrap #searchToggle.open .closeSearch,
.menuMobilBtnWrap #searchToggleLg.open .closeSearch {
  display: block;
}
.menuMobilBtnWrap button.js-offcanvas-trigger {
  height: 45px;
  width: 40px;
  background: #fff;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1;
  padding: 0;
  outline-offset: -2px;
  transition: all .2s ease;
}
.menuMobilBtnWrap button.js-offcanvas-trigger .closeMenu {
  display: none;
}
.menuMobilBtnWrap button.js-offcanvas-trigger:focus,
.menuMobilBtnWrap button.js-offcanvas-trigger:hover {
  color: #CFD82D;
}
.menuMobilBtnWrap button.js-offcanvas-trigger:focus {
  outline: 2px solid rgba(0, 117, 154, 0.75);
}
.menuMobilBtnWrap button.js-offcanvas-trigger.open .openMenu {
  display: none;
}
.menuMobilBtnWrap button.js-offcanvas-trigger.open .closeMenu {
  display: block;
}
#searchWrap {
  display: none;
  background: #607631;
  color: #fff;
  padding: 2px;
  position: relative;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
}
#searchWrap .SearchModuleSpacer {
  display: none;
}
#searchWrap .search {
  display: flex;
  flex-grow: 1;
  flex-basis: 100%;
  flex-shrink: 1;
}
#searchWrap #SearchModuleSpacer {
  display: none;
}
#searchWrap label {
  position: absolute;
  left: -999999em;
}
#searchWrap input[type="text"] {
  background: #fff;
  color: #000;
  border: none;
  border-radius: 0;
  font-size: 1rem;
  line-height: 1;
  height: 41px;
  padding: 0 1rem;
  flex-basis: 100%;
}
#searchWrap input[type="text"]::placeholder {
  color: #666;
}
#searchWrap button {
  margin: 0 0 0 10px;
  background: #fff;
  border: none;
  color: #333;
  height: 40px;
  border-radius: 4px;
  padding: 5px 15px;
  font-size: 0.875rem;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
#searchWrap button#closeSearch {
  background: #58595B;
  color: #fff;
}
#searchWrap button:focus {
  text-decoration: underline;
  box-shadow: 0 0 0px 3px rgba(65, 166, 226, 0.5);
  outline: none;
}
#searchWrap img[id*='imgbtnSearch'] {
  box-sizing: content-box;
  width: 18px;
  height: 18px;
  padding: 11px;
}
#searchWrapLg {
  position: absolute;
  width: 100%;
  bottom: 10px;
  right: 5px;
  display: none;
}
#searchWrapLg .searchWrapInner {
  background: #607631;
  position: absolute;
  right: 0;
  bottom: 4px;
  transition: width .2s ease;
  width: 0;
  overflow: hidden;
}
#searchWrapLg .searchWrapInner.open {
  width: 715px;
}
#searchWrapLg .search {
  display: flex;
  padding: 2px;
}
#searchWrapLg #SearchModuleSpacer {
  display: none;
}
#searchWrapLg label {
  position: absolute;
  left: -999999em;
}
#searchWrapLg input[type="text"] {
  background: #fff;
  color: #000;
  border: none;
  border-radius: 0;
  font-size: 1rem;
  line-height: 1;
  height: 41px;
  padding: 0 1rem;
  flex-basis: 100%;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
#searchWrapLg input[type="text"]::placeholder {
  color: #666;
}
#searchWrapLg button {
  margin: 0 0 0 10px;
  background: #fff;
  border: none;
  color: #333;
  height: 40px;
  border-radius: 4px;
  padding: 5px 15px;
  font-size: 0.875rem;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
#searchWrapLg button#closeSearch {
  background: #58595B;
  color: #fff;
}
#searchWrapLg button:focus {
  text-decoration: underline;
  box-shadow: 0 0 0px 3px rgba(65, 166, 226, 0.5);
  outline: none;
}
#searchWrapLg img[id*='imgbtnSearch'] {
  box-sizing: content-box;
  width: 18px;
  height: 18px;
  padding: 10px;
}
.alertBanner {
  background: #607631;
  color: #fff;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-bottom: 1px solid #fff;
  position: relative;
  z-index: 100;
  display: none;
}
.loggedin .alertBanner {
  display: block;
}
.alertBanner .alert {
  margin: 0;
  border: none;
  text-align: left;
  font-size: 1.625rem;
  line-height: 1.2;
  padding: 1rem 0px;
  display: flex;
  align-items: flex-start;
}
@media only screen and (min-width: 700px ) {
  .alertBanner .alert {
    font-size: 1.75rem;
  }
}
@media only screen and (min-width: 1025px ) {
  .alertBanner .alert {
    padding: 2rem 1rem;
  }
}
.alertBanner .alert button {
  background: none;
  color: #CFD82D;
  padding: 5px 5px;
  border: none;
  line-height: 1;
  font-size: 1.5rem;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease;
}
.alertBanner .alert button:hover {
  opacity: 0.5;
}
.alertBanner .alert button:focus {
  outline: 2px solid #fff;
}
.alertBanner .alert a {
  color: #fff;
  text-decoration: none;
}
.alertBanner .alert a:focus {
  outline: 2px solid #fff;
  outline-offset: 3px;
}
/* MAIN TOP HORIZONTAL NAV
---------------------------------------------*/
.desktopNav {
  position: relative;
  z-index: 99;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.desktopNav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.desktopNav ul li {
  float: left;
  padding: 0;
  position: relative;
}
.desktopNav ul li a {
  display: block;
  text-decoration: none;
}
.desktopNav li.sfHover ul {
  left: auto;
  opacity: 1;
}
.desktopNav li.sfHover ul ul {
  left: -999em;
}
.desktopNav li.sfHover ul ul ul {
  left: -999em;
}
.desktopNav li ul {
  position: absolute;
  left: -999em;
  margin: 0px;
  background: #fff;
  float: none;
  width: 250px;
  text-align: left;
  opacity: 0;
  transition: opacity 500ms ease;
  top: 100%;
}
.desktopNav li ul li {
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  line-height: normal;
  text-transform: none;
  font-size: 1em;
}
.desktopNav li ul li.sfHover {
  position: static;
}
.desktopNav li ul li.sfHover ul {
  left: auto;
  opacity: 1;
}
.desktopNav li ul li a {
  display: block;
}
.desktopNav .desktopNavInner {
  font-size: 1rem;
  line-height: normal;
  margin-right: 1rem;
}
.desktopNav .desktopNavInner > ul > li > a {
  font-weight: 500;
  color: #000;
  padding: 0.5rem 2rem 1rem;
  position: relative;
  text-transform: uppercase;
  border-bottom: 0.5rem solid transparent;
}
.desktopNav .desktopNavInner > ul > li > a:hover,
.desktopNav .desktopNavInner > ul > li > a:focus {
  text-decoration: none;
}
.desktopNav .desktopNavInner > ul > li.sfHover > a {
  color: #607631;
  border-color: #607631;
}
.desktopNav .desktopNavInner > ul > li.sfHover > a:focus {
  text-decoration: none;
  outline: solid 2px rgba(0, 117, 154, 0.75);
  outline-offset: 1px;
}
.desktopNav .desktopNavInner > ul > li.hasDD > a:after {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
  margin-left: 6px;
  padding-top: 3px;
}
.desktopNav .desktopNavInner > ul > li.hasDD.sfHover:after {
  content: "";
  position: absolute;
  z-index: 4;
  bottom: -0.3rem;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 6.5px 0 6.5px;
  border-color: #607631 transparent transparent transparent;
}
.desktopNav .desktopNavInner > ul > li > ul {
  z-index: 3;
  padding: 0.75rem 0 1.5rem;
}
.desktopNav .desktopNavInner > ul > li > ul:after {
  content: "";
  display: block;
  background: #f4f4f4;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 200vw;
  transform: translateX(-50%);
  z-index: 1;
  box-shadow: 0px 10px 11px -10px #7a7a7a inset;
}
.desktopNav .desktopNavInner > ul > li > ul > li {
  float: none;
  position: relative;
  z-index: 3;
}
.desktopNav .desktopNavInner > ul > li > ul > li > a {
  font-weight: 500;
  padding: 0.35rem 0;
  color: #000;
  font-size: 0.9375rem;
  line-height: normal;
  font-weight: 300;
  transition: none;
}
.desktopNav .desktopNavInner > ul > li > ul > li > a:hover,
.desktopNav .desktopNavInner > ul > li > ul > li > a:focus {
  color: #607631;
  text-decoration: underline;
}
.desktopNav .desktopNavInner > ul > li > ul > li > a:hover:after,
.desktopNav .desktopNavInner > ul > li > ul > li > a:focus:after {
  content: ' >>';
  position: relative;
  margin-left: 0.3125rem;
  display: inline-block;
  text-decoration: none;
}
.desktopNav .desktopNavInner > ul > li > ul > li > a:focus {
  text-decoration: underline;
  outline: solid 2px rgba(0, 117, 154, 0.75);
  outline-offset: -3px;
}
.desktopNav .desktopNavInner > ul > li.twoCol {
  float: none;
}
.desktopNav .desktopNavInner > ul > li.twoCol > ul {
  width: 500px;
  column-count: 2;
  column-width: 240px;
  column-gap: 0;
}
.desktopNav .desktopNavInner > ul > li.twoCol > ul > li {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}
/* Flexbox styles */
.flexbox .desktopNavInner > ul {
  display: flex;
}
.flexbox .desktopNavInner > ul > li {
  float: none;
  display: flex;
  flex-direction: column;
}
.flexbox .desktopNavInner > ul > li > a {
  display: flex;
  width: 100%;
  text-align: center;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
}
/* BREADCRUMBS
-----------------------------------------------*/
.structBody .breadCrumbs ol {
  padding: 0;
  margin: 0.5rem 0 1rem;
  list-style: none;
  display: flex;
  font-size: 0.625rem;
  line-height: normal;
  flex-wrap: wrap;
}
.structBody .breadCrumbs ol li {
  padding: 0 0 5px 0;
  margin: 0;
  display: inline;
  position: relative;
}
.structBody .breadCrumbs ol li + li:before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  opacity: 0.25;
  font-weight: bold;
  padding: 0 9px;
}
.structBody .breadCrumbs ol li a {
  text-decoration: none;
  color: inherit;
  position: relative;
}
.structBody .breadCrumbs ol li a:hover,
.structBody .breadCrumbs ol li a:focus {
  text-decoration: underline;
}
.structBody .breadCrumbs ol li a:focus {
  outline: 2px solid #ccc;
  outline-offset: 2px;
}
.structBody .breadCrumbs ol li.selected a {
  font-weight: bold;
}
.structBody .breadCrumbs ol li.selected a:after {
  content: "";
  background-color: #607631;
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: -4px;
  left: 0;
}
.structBody .breadCrumbs ol li.selected a:hover,
.structBody .breadCrumbs ol li.selected a:focus {
  text-decoration: none;
}
.structBody .breadCrumbs ol li.selected a:hover:after,
.structBody .breadCrumbs ol li.selected a:focus:after {
  background-color: #ccc;
}
/* BODY ELEMENTS
---------------------------------------------*/
.structBody {
  padding: 0em 0 0rem;
  width: 100%;
  background: #fff;
  clear: both;
  display: block;
  overflow: hidden;
}
@media only screen and (min-width: 700px ) {
}
.structBody.has-heroImg .heroImg {
  margin-bottom: 2rem;
}
@media only screen and (min-width: 1025px ) {
  .structBody.has-heroImg .heroImg {
    margin-bottom: 5rem;
  }
}
.loggedin .structBody {
  margin-top: 0;
  padding-top: 0!important;
}
@media only screen and (min-width: 700px ) {
  .structBody .container {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (min-width: 1025px ) {
  .structBody .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.oneCol-layout,
.twoCol-layout {
  padding-bottom: 3rem;
  padding-top: 2rem;
  padding-left: 10px;
  padding-right: 10px;
}
@media only screen and (min-width: 1025px ) {
  .oneCol-layout,
  .twoCol-layout {
    padding-top: 0;
    padding-bottom: 7rem;
    padding-left: 0;
    padding-right: 0;
  }
}
.has-heroImg .oneCol-layout,
.has-heroImg .twoCol-layout {
  padding-top: 0;
}
@media only screen and (min-width: 1025px ) {
  .has-heroImg .oneCol-layout,
  .has-heroImg .twoCol-layout {
    padding-top: 0;
  }
}
@media only screen and (min-width: 1025px ) {
  .twoCol-layout .mainWrap {
    padding-left: 45px;
  }
}
.splitCols {
  margin-top: 2rem;
}
@media only screen and (min-width: 700px ) {
  .splitCols {
    margin-top: 4rem;
  }
}
@media only screen and (min-width: 1025px ) {
  .splitCols {
    margin-top: 7rem;
  }
}
.splitCols > * + * {
  margin-top: 2rem;
}
@media only screen and (min-width: 1025px ) {
  .splitCols > * + * {
    margin-top: 0;
  }
}
.fullImg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.pageName h1 {
  text-align: left;
  margin-bottom: 4rem;
}
@media only screen and (min-width: 700px ) {
  .pageName h1 {
    text-align: left;
    font-size: 2.75rem;
  }
}
.giftBtns {
  margin: 0 0 2.5rem;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  max-width: 380px;
  justify-content: space-between;
}
@media only screen and (min-width: 700px ) {
  .giftBtns {
    margin-bottom: 5.5rem;
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 1025px ) {
  .giftBtns {
    margin-bottom: 5.5rem;
    max-width: 100%;
  }
}
.giftBtns li {
  width: 45%;
}
@media only screen and (min-width: 700px ) {
  .giftBtns li {
    padding-right: 2rem;
    width: 50%;
  }
}
@media only screen and (min-width: 1025px ) {
  .giftBtns li {
    width: auto;
  }
}
@media only screen and (min-width: 1025px ) {
}
.giftBtns li:nth-child(n+3) {
  margin-top: 1.25rem;
}
@media only screen and (min-width: 700px ) {
  .giftBtns li:nth-child(n+3) {
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 1025px ) {
  .giftBtns li:nth-child(n+3) {
    margin-top: 0;
  }
}
.giftBtns li .button {
  display: block;
  min-width: 7.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
a[id$='SendPage'],
a[id$='ToggleDisablePage'] {
  display: none;
}
/* NEWS LISTINGS
-----------------------------------------------*/
.newsItem {
  position: relative;
  padding: 0px 0 0px;
  float: left;
  width: 100%;
  clear: both;
  margin-bottom: 30px;
}
@media only screen and (min-width: 700px ) {
  .newsItem {
    margin-bottom: 50px;
  }
}
.newsItem + .newsItem {
  padding-top: 30px;
  border-top: 1px solid #D8D8D8;
}
@media only screen and (min-width: 700px ) {
  .newsItem + .newsItem {
    padding-top: 50px;
  }
}
.newsItem:first-of-type {
  margin-top: 20px;
}
@media only screen and (min-width: 700px ) {
  .newsItem:first-of-type {
    margin-top: 20px;
  }
}
.newsItem .tools a {
  display: inline-block;
}
.newsItem .thumb {
  position: relative;
  width: 100%;
  margin: 0 0 15px;
}
@media only screen and (min-width: 700px ) {
  .newsItem .thumb {
    float: left;
    margin: 0;
    width: 250px;
  }
}
@media only screen and (min-width: 700px ) {
  .newsItem .text {
    padding-left: 280px;
  }
}
.newsItem .category {
  font-size: 1rem;
  letter-spacing: 0.5px;
}
.newsItem .title {
  font-size: 1.375rem;
  line-height: normal;
  font-weight: 500;
  color: #607631;
  margin-bottom: 10px;
  display: block;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
@media only screen and (min-width: 1025px ) {
  .newsItem .title {
    font-size: 1.625rem;
  }
}
.newsItem .pubDate {
  font-style: italic;
  display: block;
  font-size: 0.825rem;
  line-height: normal;
  margin: 7px 0;
}
@media only screen and (min-width: 1025px ) {
  .newsItem .preview {
    font-size: 1rem;
  }
}
.newsItem a {
  display: block;
  text-decoration: none;
  color: inherit;
  overflow: hidden;
}
.newsItem a:hover .title,
.newsItem a:focus .title {
  text-decoration: underline;
}
.newsItem a:focus {
  box-shadow: 0 0 0px 3px rgba(65, 166, 226, 0.5);
}
/* EXPAND/COLLAPSE STYLES
---------------------------------------------*/
.accordion {
  margin: 1.5rem 0 3rem;
  width: 100%;
  clear: both;
}
@media only screen and (min-width: 700px ) {
  .accordion {
    margin-bottom: 4rem;
  }
}
.accordion .accordion {
  margin: 0;
}
.accordion .collapseItem {
  margin: 0 0px;
  /* heading */
  /* content */
}
.accordion .collapseItem .panel-heading {
  display: block;
  font-weight: 500;
  position: relative;
  font-size: 1.125rem;
  line-height: 1.2;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}
.accordion .collapseItem .panel-heading a {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5rem 0;
  text-decoration: none;
  color: #000;
  align-items: center;
}
@media only screen and (min-width: 700px ) {
  .accordion .collapseItem .panel-heading a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.accordion .collapseItem .panel-heading a:focus {
  outline: 2px solid rgba(0, 117, 154, 0.75);
}
.accordion .collapseItem .panel-heading a .accText {
  min-height: 2rem;
  display: flex;
  align-items: center;
  padding-right: 1rem;
}
@media only screen and (min-width: 700px ) {
  .accordion .collapseItem .panel-heading a .accText {
    padding-right: 2rem;
  }
}
.accordion .collapseItem .panel-heading a .arrows {
  order: 2;
  height: 2rem;
  width: 2rem;
  font-size: 1.125rem;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 700px ) {
}
.accordion .collapseItem .panel-heading a.open {
  color: #607631;
}
.accordion .collapseItem .accordionPanelWrap {
  padding: 1rem 0 2.25rem;
  overflow: hidden;
  border-top: 0.5rem solid #607631;
  position: relative;
}
.accordion .collapseItem .accordionPanelWrap:before {
  content: "";
  position: absolute;
  z-index: 4;
  top: 0;
  left: 1rem;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 6.5px 0 6.5px;
  border-color: #607631 transparent transparent transparent;
}
@media only screen and (min-width: 700px ) {
  .accordion .collapseItem .accordionPanelWrap {
    padding-left: 0;
  }
}
@media only screen and (min-width: 1025px ) {
  .accordion .collapseItem .accordionPanelWrap {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 700px) and (max-width:  1024px ) {
  .featuresWrap {
    margin-left: -5px;
    margin-right: -5px;
  }
}
@media only screen and (min-width: 700px) and (max-width:  1024px ) {
  .featureCard {
    padding: 5px;
  }
}
@media only screen and (min-width: 1025px ) {
  .featureCard {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.featureCard + .featureCard {
  margin-top: 15px;
}
@media only screen and (min-width: 700px ) {
  .featureCard + .featureCard {
    margin-top: 0;
  }
}
.featureCard a {
  display: block;
  text-decoration: none;
  color: inherit;
  height: 100%;
}
.featureCard a:hover .title:after {
  width: 75px;
}
@media only screen and (min-width: 1025px ) {
  .featureCard a:hover .title:after {
    width: 130px;
  }
}
.featureCard a:focus {
  outline: 2px solid rgba(0, 0, 0, 0.5);
  outline-offset: 3px;
  text-decoration: underline;
}
.featureCard .tools a {
  display: inline-block;
}
.featureCard .cardInner {
  display: flex;
  min-height: 125px;
  background: #fff;
}
@media only screen and (min-width: 700px ) {
  .featureCard .cardInner {
    flex-direction: column;
    height: 100%;
  }
}
@media only screen and (min-width: 1025px ) {
  .featureCard .cardInner {
    background: none;
  }
}
.featureCard .thumb {
  width: 100px;
  background-size: cover;
  background-position: center;
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 100px;
}
@media only screen and (min-width: 700px ) {
  .featureCard .thumb {
    width: 100%;
    flex-basis: auto;
    padding-bottom: 100%;
  }
}
.featureCard .text {
  background: #F6F6F6;
  padding: 15px;
  width: 100%;
}
@media only screen and (min-width: 700px ) {
  .featureCard .text {
    text-align: center;
    padding: 15px 10px;
    height: 100%;
  }
}
@media only screen and (min-width: 1025px ) {
  .featureCard .text {
    margin: -30px 15px 0;
    padding: 20px 15px;
    width: auto;
    height: 100%;
  }
}
.featureCard .text .title {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.15;
  color: #607631;
}
@media only screen and (min-width: 1025px ) {
  .featureCard .text .title {
    font-size: 1.375rem;
  }
}
.cardsWrap {
  margin-left: -10px;
  margin-right: -10px;
}
@media only screen and (min-width: 700px ) {
  .cardsWrap {
    margin-left: 0;
    margin-right: 0;
  }
}
.cardsWrap ul {
  margin-top: 10px;
  margin-bottom: 40px;
  padding: 0;
}
@media only screen and (min-width: 700px ) {
  .cardsWrap ul {
    margin-top: 20px;
  }
}
.cardItem {
  list-style: none;
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (min-width: 700px ) {
  .cardItem {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 1025px ) {
  .cardItem {
    margin-bottom: 30px;
  }
}
.cardItem + .cardItem {
  margin-top: 30px;
}
@media only screen and (min-width: 700px ) {
  .cardItem + .cardItem {
    margin-top: 1.75rem;
  }
}
@media only screen and (min-width: 1025px ) {
  .cardItem + .cardItem {
    margin-top: 0;
  }
}
.cardItem a {
  display: block;
  color: inherit;
  text-decoration: none;
  height: 100%;
}
.cardItem a .cardInner .thumb {
  background-size: auto 115%;
}
.cardItem a .cardInner .text .cta {
  display: block;
}
.cardItem a:hover .cardInner .thumb,
.cardItem a:focus .cardInner .thumb {
  background-size: auto 100%;
}
@media only screen and (min-width: 700px ) {
  .cardItem a:hover .cardInner .thumb,
  .cardItem a:focus .cardInner .thumb {
    background-size: auto 100%;
  }
}
@media only screen and (min-width: 1025px ) {
  .cardItem a:hover .cardInner .thumb,
  .cardItem a:focus .cardInner .thumb {
    background-size: auto 100%;
  }
}
.cardItem a:hover .cardInner .text .title,
.cardItem a:focus .cardInner .text .title {
  text-decoration: underline;
}
.cardItem a:hover .cardInner .text .cta .button,
.cardItem a:focus .cardInner .text .cta .button {
  background: #CFD82D;
}
.cardItem a:hover .cardInner .text .cta .button:after,
.cardItem a:focus .cardInner .text .cta .button:after {
  transform: translateX(4px);
}
.cardItem a:focus .cardInner {
  outline: 2px solid rgba(0, 117, 154, 0.5);
  outline-offset: 3px;
}
.cardItem .tools a {
  display: inline-block;
  height: auto;
}
.cardItem .cardInner {
  position: relative;
}
@media only screen and (min-width: 700px ) {
  .cardItem .cardInner {
    height: 100%;
    display: flex;
  }
}
@media only screen and (min-width: 1025px ) {
  .cardItem .cardInner {
    flex-direction: column;
  }
}
.cardItem .cardInner .thumb {
  flex-grow: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: background-size 0.2s ease;
}
@media only screen and (min-width: 700px ) {
  .cardItem .cardInner .thumb {
    padding-bottom: 35%;
    flex-shrink: 0;
    width: 42%;
  }
}
@media only screen and (min-width: 1025px ) {
  .cardItem .cardInner .thumb {
    width: 100%;
    padding-bottom: 85%;
    background-size: auto 115%;
  }
}
.cardItem .cardInner .thumb img {
  visibility: hidden;
}
@media only screen and (min-width: 700px ) {
  .cardItem .cardInner .thumb img {
    display: none;
  }
}
.cardItem .cardInner .text {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: #607631;
  color: #fff;
  padding: 1.125rem 1rem;
}
@media only screen and (min-width: 700px ) {
  .cardItem .cardInner .text {
    padding: 1.125rem 1.5rem 1.125rem;
  }
}
@media only screen and (min-width: 1025px ) {
  .cardItem .cardInner .text {
    padding: 1rem 1.5rem;
    font-size: 1rem;
  }
}
.cardItem .cardInner .text :last-child {
  margin-top: auto;
}
.cardItem .cardInner .text .title {
  display: block;
  margin: 0 0 1.125rem;
  color: #fff;
}
.cardItem .cardInner .text .title a {
  display: block;
}
.cardItem .cardInner .text .title a:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
}
.cardItem .cardInner .text .title a:focus:after {
  outline: 2px solid rgba(0, 117, 154, 0.75);
  outline-offset: 2px;
}
.cardItem .cardInner .text .textInner {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 700px ) {
  .cardItem .cardInner .text .textInner {
    flex-grow: 1;
  }
}
@media only screen and (min-width: 1025px ) {
  .cardItem .cardInner .text .textInner {
    flex-direction: column;
    height: 100%;
  }
}
.cardItem .cardInner .text .preview {
  flex-basis: auto;
  flex-grow: 1;
  font-weight: 500;
  line-height: 1.4;
}
.cardItem .cardInner .text .cta {
  display: none;
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: uppercase;
  padding: 5px 5px 5px 0;
}
@media only screen and (min-width: 700px ) {
  .cardItem .cardInner .text .cta {
    padding: 0;
    margin: 2rem 0 0 0;
  }
}
@media only screen and (min-width: 1025px ) {
  .cardItem .cardInner .text .cta {
    margin-top: 1rem;
    margin-left: 0;
  }
}
.cardItem .cardInner .text .cta .button {
  margin-top: 20px;
  margin-bottom: 0;
  white-space: nowrap;
}
@media only screen and (min-width: 700px ) {
  .cardItem .cardInner .text .cta .button {
    margin: 0;
  }
}
.cardItem .cardInner .text .cta a .button {
  display: inline-block;
}
.cardsClickWrap ul {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0;
  list-style: none;
}
@media only screen and (min-width: 1025px ) {
  .cardClickItem {
    margin-bottom: 30px;
  }
}
.cardClickItem + .cardClickItem {
  margin-top: 3rem;
}
@media only screen and (min-width: 700px ) {
  .cardClickItem + .cardClickItem {
    margin-top: 1.75rem;
  }
}
@media only screen and (min-width: 1025px ) {
  .cardClickItem + .cardClickItem {
    margin-top: 0;
  }
}
.cardClickItem .cardInner {
  position: relative;
  overflow: hidden;
}
.cardClickItem .tools a {
  display: inline-block;
}
.cardClickItem a {
  display: block;
}
.cardClickItem a .thumb {
  transform: scale(1.15);
  transition: transform .2s ease;
}
.cardClickItem a .text {
  position: absolute;
  bottom: -100%;
}
@media only screen and (min-width: 1025px ) {
}
.cardClickItem a:hover .thumb,
.cardClickItem a:focus .thumb {
  transform: scale(1);
}
.cardClickItem a:hover .text,
.cardClickItem a:focus .text {
  bottom: 0;
}
.cardClickItem a:focus {
  outline: 2px solid rgba(0, 117, 154, 0.75);
  outline-offset: 2px;
}
.cardClickItem .thumb {
  position: relative;
  z-index: 2;
  transition: transform 0.4s;
  padding-bottom: 70%;
  background-position: center;
  background-size: cover;
}
@media only screen and (min-width: 700px ) {
  .cardClickItem .thumb {
    padding-bottom: 40%;
  }
}
@media only screen and (min-width: 1025px ) {
  .cardClickItem .thumb {
    padding-bottom: 115%;
  }
}
.cardClickItem .thumb img {
  display: none;
}
.cardClickItem .text {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #607631;
  padding: 0.5rem 1rem;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  position: absolute;
  z-index: 3;
  transition: bottom 0.25s ease-out;
}
.cardClickItem .text .title {
  color: #fff;
  text-align: center;
  margin: 0;
}
.cardClickItem .text .preview {
  display: none;
}
.statsWrap ul {
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding: 0;
  list-style: none;
}
@media only screen and (min-width: 1025px ) {
  .statsWrap ul {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
}
.statItem {
  list-style: none;
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (min-width: 1025px ) {
  .statItem {
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .statItem:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}
@media only screen and (min-width: 1025px ) {
  .interior .statItem {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.statItem + .statItem {
  margin-top: 2rem;
}
@media only screen and (min-width: 1025px ) {
  .statItem + .statItem {
    margin-top: 0;
  }
}
.statItem .inner {
  border: 3px solid #607631;
  border-left-width: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 2rem;
  text-align: center;
  line-height: 1.4;
}
@media only screen and (min-width: 700px ) {
  .statItem .inner {
    padding: 2.5rem 4rem;
  }
}
@media only screen and (min-width: 1025px ) {
  .statItem .inner {
    padding: 1.5rem 2rem;
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    line-height: 1.7;
    font-size: 1rem;
  }
}
.statItem .inner .value {
  color: #607631;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.75rem;
  line-height: 1;
  display: block;
  font-weight: 500;
  margin: 0 0 0.5rem;
}
@media only screen and (min-width: 700px ) {
  .statItem .inner .value {
    font-size: 4rem;
  }
}
@media only screen and (min-width: 1025px ) {
  .statItem .inner .value {
    margin: 0 0 2rem;
  }
}
@media only screen and (min-width: 1025px ) {
  .interior .statItem .inner .value {
    color: #000;
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
}
.infographicsWrap {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}
@media only screen and (min-width: 1025px ) {
  .infographicsWrap {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
}
.infographicsWrap ul {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}
.infographicItem {
  list-style: none;
}
@media only screen and (min-width: 700px ) {
  .infographicItem:after {
    content: "";
    display: block;
    padding-bottom: 105%;
    border: 3px solid #607631;
    border-radius: 50%;
  }
}
@media only screen and (min-width: 1025px ) {
  .infographicItem:after {
    content: "";
    padding-bottom: 100%;
  }
}
.infographicItem + .infographicItem {
  margin-top: 3rem;
}
@media only screen and (min-width: 700px ) {
  .infographicItem + .infographicItem {
    margin-top: 0;
  }
}
@media only screen and (min-width: 700px ) {
  .infographicItem:nth-child(3n+1) .inner {
    padding-left: 1.5rem;
  }
}
.infographicItem:nth-child(3n+1):after {
  margin-right: -1rem;
}
@media only screen and (min-width: 700px ) {
  .infographicItem:nth-child(3n+3) .inner {
    padding-right: 1.5rem;
  }
}
.infographicItem:nth-child(3n+3):after {
  margin-left: -1rem;
}
.infographicItem:nth-child(even):after {
  border: 5px dotted #CFD82D;
  transform: scale(1.35) rotate(72deg);
  padding-bottom: 95%;
}
@media only screen and (min-width: 1025px ) {
  .infographicItem:nth-child(even):after {
    border: 10px dotted #CFD82D;
    transform: scale(1.3) rotate(113deg);
  }
}
@media only screen and (min-width: 1025px ) {
  .infographicItem:nth-child(even) .inner .value {
    font-size: 3.25rem;
  }
}
.infographicItem:nth-child(even) .inner .value:after {
  background-color: #CFD82D;
}
.infographicItem .tools {
  position: relative;
  z-index: 10;
}
.infographicItem .inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.4;
}
@media only screen and (min-width: 700px ) {
}
@media only screen and (min-width: 700px ) {
  .infographicItem .inner {
    padding: 0rem 0.5rem;
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    line-height: 1.7;
  }
}
.infographicItem .inner .thumb {
  display: none;
}
@media only screen and (min-width: 700px ) {
  .infographicItem .inner .thumb {
    display: block;
  }
}
.infographicItem .inner .thumb img {
  width: 35%;
  display: block;
  margin: 0 auto 0.875rem;
}
@media only screen and (min-width: 1025px ) {
  .infographicItem .inner .thumb img {
    width: 50%;
  }
}
.infographicItem .inner .value {
  color: #000;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 2.75rem;
  line-height: 1;
  display: block;
  font-weight: 500;
}
@media only screen and (min-width: 700px ) {
  .infographicItem .inner .value {
    font-size: 1.75rem;
    margin-bottom: 0.75rem;
  }
}
.infographicItem .inner .value:after {
  content: "";
  height: 3px;
  display: block;
  width: 100px;
  background: #607631;
  margin: 0.875rem auto;
}
@media only screen and (min-width: 700px ) {
  .infographicItem .inner .value:after {
    display: none;
  }
}
.infographicItem .inner .text {
  line-height: 1.5;
}
@media (min-width: 950px) {
  .infographicItem .inner .text {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media only screen and (min-width: 1025px ) {
  .infographicItem .inner .text {
    padding-left: 7%;
    padding-right: 7%;
  }
}
.simpleSliderWrap {
  position: relative;
  margin-top: 5.5rem;
  margin-bottom: 4rem;
}
@media only screen and (min-width: 1025px ) {
  .simpleSliderWrap {
    margin-top: 7.5rem;
    margin-bottom: 5.625rem;
  }
}
@media only screen and (min-width: 700px ) {
  .simpleSliderWrap:after {
    content: "";
    background-image: url(images/slider-dots.svg);
    width: 200px;
    height: 116px;
    background-size: auto 100%;
    display: block;
    position: absolute;
    right: 15px;
    top: 400px;
  }
}
@media only screen and (min-width: 1025px ) {
  .simpleSliderWrap:after {
    top: 50%;
    transform: translateY(50%);
  }
  .interior .simpleSliderWrap:after {
    right: -8.3333%;
  }
}
.simpleSliderWrap .simpleSliderWrapInner {
  padding: 0;
}
@media only screen and (min-width: 700px ) {
  .simpleSliderWrap .simpleSliderWrapInner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.simpleSliderWrap .simpleSlider {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 20px;
  margin-bottom: 0;
  margin-left: -10px;
  margin-right: -10px;
}
@media only screen and (min-width: 700px ) {
  .simpleSliderWrap .simpleSlider {
    padding-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 1025px ) {
  .simpleSliderWrap .simpleSlider {
    padding-bottom: 45px;
  }
}
@media only screen and (min-width: 700px ) {
  .interior .simpleSliderWrap .simpleSlider {
    padding-left: 0;
    padding-right: 0;
  }
}
.simpleSliderWrap .slick-arrow {
  position: absolute;
  top: 0;
  z-index: 5;
  padding-bottom: 75%;
  width: 50px;
}
@media only screen and (min-width: 700px ) {
  .simpleSliderWrap .slick-arrow {
    height: 600px;
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 1025px ) {
  .simpleSliderWrap .slick-arrow {
    height: 100%;
  }
}
.simpleSliderWrap .slick-arrow.arrow-prev {
  left: 1rem;
}
@media only screen and (min-width: 700px ) {
  .simpleSliderWrap .slick-arrow.arrow-prev {
    left: 0px;
  }
}
@media only screen and (min-width: 1025px ) {
  .simpleSliderWrap .slick-arrow.arrow-prev {
    left: -8%;
  }
}
.simpleSliderWrap .slick-arrow.arrow-prev button {
  text-align: left;
}
.simpleSliderWrap .slick-arrow.arrow-next {
  right: 1rem;
}
@media only screen and (min-width: 700px ) {
  .simpleSliderWrap .slick-arrow.arrow-next {
    right: 50px;
  }
}
@media only screen and (min-width: 1025px ) {
  .simpleSliderWrap .slick-arrow.arrow-next {
    right: -8%;
  }
}
.simpleSliderWrap .slick-arrow.arrow-next button {
  text-align: right;
}
.simpleSliderWrap .slick-arrow button {
  color: #fff;
  width: 50px;
  top: 50%;
  padding: 0 5px;
  transform: translateY(-50%);
  transition: padding 0.2s ease-in-out;
  font-size: 3rem;
  height: 50px;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.75);
  left: 0;
  right: 0;
}
.simpleSliderWrap .slick-arrow button:hover,
.simpleSliderWrap .slick-arrow button:focus {
  padding: 0px;
  opacity: 0.8;
}
.simpleSliderWrap .slick-arrow button:focus {
  outline: 2px solid rgba(0, 117, 154, 0.75);
}
@media only screen and (min-width: 700px ) {
  .simpleSliderWrap .slick-arrow button {
    color: #000;
    font-size: 1.875rem;
    text-shadow: none;
  }
}
@media only screen and (min-width: 1025px ) {
  .simpleSliderWrap .slick-arrow button {
    height: 50px;
    font-size: 2.5rem;
  }
}
.simpleSliderWrap .slick-dots {
  bottom: 0;
}
@media only screen and (min-width: 700px ) {
  .simpleSliderWrap .slick-dots {
    top: 550px;
    bottom: auto;
  }
}
@media only screen and (min-width: 1025px ) {
  .simpleSliderWrap .slick-dots {
    bottom: 0;
    top: auto;
  }
}
.simpleSliderWrap .slick-dots li {
  margin: 0 10px;
}
.simpleSliderWrap .slick-dots li button {
  width: 11px;
  height: 11px;
  background: #CFCDCD;
  border-radius: 20px;
}
.simpleSliderWrap .slick-dots li button .slick-dot-icon {
  display: none;
}
.simpleSliderWrap .slick-dots li button:focus {
  outline: 2px solid rgba(0, 117, 154, 0.75);
}
.simpleSliderWrap .slick-dots li.slick-active button {
  background: #CFD82D;
}
.simpleSliderItem .thumb {
  overflow: hidden;
}
@media only screen and (min-width: 1025px ) {
  .simpleSliderItem .thumb {
    padding-left: 0;
    padding-right: 0;
  }
}
.simpleSliderItem .thumb .inner {
  position: relative;
  overflow: hidden;
  background: #607631;
  padding-bottom: 75%;
}
@media only screen and (min-width: 700px ) {
  .simpleSliderItem .thumb .inner {
    height: 600px;
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 1025px ) {
  .simpleSliderItem .thumb .inner {
    height: auto;
  }
}
.simpleSliderItem .thumb .inner img {
  display: none;
}
@media only screen and (min-width: 700px ) {
  .simpleSliderItem .thumb .inner img {
    display: block;
    visibility: hidden;
  }
}
.simpleSliderItem .thumb .inner > div {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  transition: all .35s ease;
  opacity: 1;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
@media only screen and (min-width: 1025px ) {
  .simpleSliderItem .thumb .inner > div {
    position: relative;
  }
}
.simpleSliderItem .text {
  padding: 1.5rem 2.5rem 0;
  text-align: center;
}
@media only screen and (min-width: 700px ) {
  .simpleSliderItem .text {
    text-align: left;
    padding: 2rem 15px 0;
  }
}
@media only screen and (min-width: 1025px ) {
  .simpleSliderItem .text {
    padding-top: 2.5rem;
    padding-right: 2.5rem;
    font-size: 1rem;
  }
  .interior .simpleSliderItem .text {
    padding-left: 15px;
  }
}
.simpleSliderItem .text .subHead {
  margin-bottom: 1.5rem;
}
.simpleSliderItem .text .title {
  margin: 0 0 1.25rem;
}
@media only screen and (min-width: 700px ) {
  .simpleSliderItem .text .title {
    padding-right: 2.5rem;
  }
}
@media only screen and (min-width: 1025px ) {
  .simpleSliderItem .text .title {
    padding-right: 0;
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 1025px ) {
  .simpleSliderItem .text .preview {
    min-height: 6rem;
  }
}
.simpleSliderItem .text .preview .button {
  clear: both;
  margin-top: 1.5rem;
}
.impactBlock {
  margin: 4.5rem 0;
}
@media only screen and (min-width: 1025px ) {
  .impactBlock {
    margin: 8rem 0;
  }
}
.impactBlock:after {
  content: "";
  display: none;
  background-image: url(images/slider-dots.svg);
  width: 235px;
  height: 60px;
}
@media only screen and (min-width: 700px ) {
  .impactBlock:after {
    display: block;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (min-width: 1025px ) {
  .impactBlock:after {
    transform: translateY(-50%);
    width: 260px;
    margin-left: 0;
    margin-top: 0;
  }
}
@media only screen and (min-width: 1025px ) {
  .impactBlock.skinny:after {
    margin-left: -8.3333%;
  }
}
.impactBlock.animate .inner:before,
.impactBlock.animate .inner2:before {
  height: 100%;
}
.impactBlock.animate .inner:after,
.impactBlock.animate .inner2:after {
  width: 100%;
}
.impactBlock .tools a {
  display: inline-block;
}
.impactBlock a {
  display: block;
  text-decoration: none;
}
.impactBlock a:hover,
.impactBlock a:focus {
  text-decoration: underline;
}
.impactBlock .inner:before,
.impactBlock .inner2:before,
.impactBlock .inner:after,
.impactBlock .inner2:after {
  background: #CFD82D;
  position: absolute;
  content: "";
  transition: all .2s linear;
}
@media only screen and (min-width: 700px ) {
  .impactBlock .inner:before,
  .impactBlock .inner2:before,
  .impactBlock .inner:after,
  .impactBlock .inner2:after {
    transition: all .3s linear;
  }
}
.impactBlock .inner:before,
.impactBlock .inner2:before {
  height: 145px;
  width: 3px;
}
.impactBlock .inner:after,
.impactBlock .inner2:after {
  height: 3px;
  width: 175px;
}
@media only screen and (min-width: 700px ) {
  .impactBlock .inner:after,
  .impactBlock .inner2:after {
    width: 325px;
  }
}
@media only screen and (min-width: 1025px ) {
  .impactBlock .inner:after,
  .impactBlock .inner2:after {
    width: 45%;
  }
}
.impactBlock .inner {
  position: relative;
  text-align: center;
  padding: 2.5rem 1.25rem;
}
@media only screen and (min-width: 700px ) {
  .impactBlock .inner {
    padding: 5rem 10%;
  }
}
.impactBlock .inner:before,
.impactBlock .inner:after {
  top: 0;
  left: 0;
}
.impactBlock .inner .inner2:before,
.impactBlock .inner .inner2:after {
  bottom: 0;
  right: 0;
}
.impactBlock .inner .title {
  font-size: 2.75rem;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  color: #607631;
  line-height: 1.25;
  font-weight: 500;
}
@media only screen and (min-width: 700px ) {
  .impactBlock .inner .title {
    font-size: 2.5rem;
    line-height: 1.3;
  }
}
@media only screen and (min-width: 1025px ) {
  .impactBlock .inner .title {
    font-size: 3.25rem;
  }
}
.featureBlock {
  position: relative;
  margin: 1.875rem 0;
}
@media only screen and (min-width: 700px ) {
  .featureBlock {
    margin: 3rem 0 0;
    padding-bottom: 75px;
  }
}
@media only screen and (min-width: 1025px ) {
  .featureBlock {
    margin: 6rem 0 5rem;
    padding-bottom: 55px;
  }
}
@media only screen and (min-width: 1025px ) {
  .interior .featureBlock {
    margin-left: -8.33333%;
    margin-right: -8.33333%;
  }
}
.featureBlock:after {
  content: "";
  display: none;
  background-image: url(images/slider-dots.svg);
  width: 200px;
  height: 116px;
}
@media only screen and (min-width: 700px ) {
  .featureBlock:after {
    display: block;
    margin-right: 0;
    margin-left: auto;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
.featureBlock .thumbWrap {
  background-color: #607631;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1.75rem;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 700px ) {
}
@media only screen and (min-width: 1025px ) {
  .featureBlock .thumbWrap {
    margin-bottom: 0;
  }
}
.featureBlock .thumb {
  padding-bottom: 100%;
  background-position: center;
  transition: all .2s ease;
  background-color: #607631;
  opacity: 1;
  font-size: 0;
  line-height: 0;
  background-size: cover;
}
.featureBlock .text {
  text-align: center;
  padding: 0 1rem;
}
@media only screen and (min-width: 700px ) {
  .featureBlock .text {
    text-align: left;
    padding: 0;
  }
}
@media only screen and (min-width: 1025px ) {
  .featureBlock .text {
    padding-top: 1.5rem;
  }
}
.featureBlock .text .subtitle {
  margin-bottom: 1rem;
}
@media only screen and (min-width: 700px ) {
  .featureBlock .text .subtitle {
    margin-bottom: 1.5rem;
  }
}
.featureBlock .text .title {
  margin-bottom: 1.5rem;
}
.featureBlock .text .preview {
  display: none;
}
@media only screen and (min-width: 700px ) {
  .featureBlock .text .preview {
    display: block;
  }
}
.featureBlock .text .cta .button {
  margin: 0;
}
@media only screen and (min-width: 700px ) {
  .featureBlock .text .cta .button {
    margin-top: 1.5rem;
  }
}
@media only screen and (min-width: 1025px ) {
  .featureBlock .text .cta .button {
    margin-top: 3rem;
  }
}
.quoteItem {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
@media only screen and (min-width: 700px ) {
  .quoteItem {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
}
.quoteItem .thumb {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 3.5rem;
}
@media only screen and (min-width: 700px ) {
  .quoteItem .thumb {
    padding-bottom: 85%;
  }
}
@media only screen and (min-width: 1025px ) {
  .quoteItem .thumb {
    height: 485px;
    padding-bottom: 0;
    margin: 0;
  }
}
.quoteItem .thumb img {
  display: none;
}
.quoteItem .thumbWrap {
  position: relative;
}
@media only screen and (min-width: 700px ) {
  .quoteItem .thumbWrap {
    max-width: 600px;
  }
}
.quoteItem .thumbWrap:after {
  content: "";
  display: none;
  background-image: url(images/slider-dots.svg);
  width: 160px;
  height: 98px;
  position: absolute;
  top: 50%;
  right: -80px;
  transform: translateY(-50%);
}
@media only screen and (min-width: 700px ) {
  .quoteItem .thumbWrap:after {
    display: block;
  }
}
.quoteItem .thumbWrap.leftPic:after {
  right: auto;
  left: -80px;
}
@media only screen and (min-width: 1025px ) {
  .quoteItem .text {
    font-size: 1rem;
  }
}
.quoteItem .text blockquote {
  margin-bottom: 2rem;
}
@media only screen and (min-width: 700px ) {
  .quoteItem .text blockquote {
    margin-bottom: 3.5rem;
  }
}
@media only screen and (min-width: 1025px ) {
  .quoteItem .text blockquote {
    margin-bottom: 2rem;
  }
}
.impactBoxBlock .impactInner {
  border: 3px solid #607631;
  border-left-width: 10px;
  padding: 3rem 1.5rem;
  text-align: center;
  margin: 1.5rem 0;
}
@media only screen and (min-width: 700px ) {
  .impactBoxBlock .impactInner {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    margin: 3.5rem 0;
  }
}
.impactBoxBlock .impactInner .title {
  margin: 0;
}
.impactBoxBlock .impactInner > a {
  max-width: 250px;
}
.impactBoxBlock .impactInner .button {
  margin: 1.25rem 0 0 0;
}
@media only screen and (min-width: 700px ) {
  .impactBoxBlock .impactInner .button {
    margin: 0 0 0 1rem;
  }
}
.colorBlockWrap {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  margin-left: -10px;
  margin-right: -10px;
}
@media only screen and (min-width: 700px ) {
  .colorBlockWrap {
    margin-left: -35px;
    margin-right: -35px;
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
}
@media only screen and (min-width: 1025px ) {
  .colorBlockWrap {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}
@media only screen and (min-width: 1025px ) {
  .interior .colorBlockWrap .colorBlockInnerWrap {
    margin-left: -8.3333%;
    margin-right: -8.333%;
  }
}
.colorBlock {
  position: relative;
}
@media only screen and (min-width: 1025px ) {
  .colorBlock {
    background: transparent;
  }
}
.colorBlock .tools {
  position: relative;
  z-index: 10;
}
.colorBlock a {
  text-decoration: none;
  color: inherit;
}
.colorBlock a:hover .title,
.colorBlock a:focus .title {
  text-decoration: underline;
}
.colorBlock a:hover .thumb,
.colorBlock a:focus .thumb {
  transform: scale(1);
}
.colorBlock .thumbWrap {
  position: absolute;
  right: 0;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 1;
  overflow: hidden;
}
@media only screen and (min-width: 1025px ) {
  .colorBlock .thumbWrap {
    width: 50vw;
  }
}
.colorBlock .thumbWrap .thumb {
  transform: scale(1.15);
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  top: 0;
  transition: transform 0.5s ease-in-out;
}
.colorBlock .inner {
  overflow: hidden;
  padding: 1.5rem 15px;
  min-height: 65vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
}
@media only screen and (min-width: 700px ) {
  .colorBlock .inner {
    min-height: 50vh;
  }
}
@media only screen and (min-width: 1025px ) {
  .colorBlock .inner {
    min-height: 600px;
  }
}
@media (min-width: 1250px) {
  .colorBlock .inner {
    padding-left: 0;
    padding-right: 0;
  }
}
.colorBlock .inner .title {
  margin-bottom: 2.5rem;
}
.colorBlock .inner .preview {
  font-weight: 500;
}
@media only screen and (min-width: 1025px ) {
  .colorBlock .inner .preview {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1025px ) {
  .colorBlock:first-child:after,
  .colorBlock:nth-child(4n+5):after {
    content: "";
    width: 50vw;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }
  .colorBlock:first-child .inner,
  .colorBlock:nth-child(4n+5) .inner {
    padding-right: 3.5rem;
  }
}
@media only screen and (min-width: 1025px ) {
  .colorBlock:nth-child(4n+4):after {
    content: "";
    width: 50vw;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
  .colorBlock:nth-child(4n+4) .inner {
    padding-left: 3.5rem;
  }
  .colorBlock:nth-child(4n+4) .thumbWrap {
    right: auto;
    left: 0;
  }
}
@media only screen and (min-width: 1025px ) {
  .colorBlock:nth-child(4n+2):after {
    content: "";
    width: 50vw;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
  .colorBlock:nth-child(4n+2) .inner {
    padding-left: 3.5rem;
  }
  .colorBlock:nth-child(4n+2) .thumbWrap {
    right: auto;
    left: 0;
  }
}
@media only screen and (min-width: 1025px ) {
  .colorBlock:nth-child(4n+3):after {
    content: "";
    width: 50vw;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
  .colorBlock:nth-child(4n+3) .inner {
    padding-right: 3.5rem;
  }
}
.colorBlock.imgOnly {
  padding-bottom: 90%;
}
@media only screen and (min-width: 700px ) {
  .colorBlock.imgOnly {
    padding-bottom: 60%;
  }
}
@media only screen and (min-width: 1025px ) {
  .colorBlock.imgOnly {
    padding-bottom: 0;
  }
}
.ctaBoxWrap {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
@media only screen and (min-width: 1025px ) {
  .ctaBoxWrap {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
}
.ctaBoxItem + .ctaBoxItem {
  margin-top: 1.5rem;
}
@media only screen and (min-width: 1025px ) {
  .ctaBoxItem + .ctaBoxItem {
    margin-top: 0;
  }
}
@media only screen and (min-width: 1025px ) {
  .ctaBoxItem:nth-child(n+4) {
    margin-top: 30px;
  }
}
.ctaBoxItem .tools a {
  display: inline-block;
}
.ctaBoxItem .tools a:after {
  display: none;
}
.ctaBoxItem > a {
  display: block;
  text-decoration: none;
  color: inherit;
  height: 100%;
}
.ctaBoxItem > a:after {
  content: "\f30b";
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
  position: absolute;
  bottom: 0;
  right: 15px;
  width: 70px;
  text-align: center;
  font-size: 28px;
  text-align: right;
  padding-right: 10px;
}
@media only screen and (min-width: 700px ) {
  .ctaBoxItem > a:after {
    width: 100px;
    padding-right: 18px;
    padding-bottom: 11px;
  }
}
@media only screen and (min-width: 1025px ) {
  .ctaBoxItem > a:after {
    width: 130px;
    padding-right: 28px;
    padding-bottom: 18px;
  }
}
.ctaBoxItem > a:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 70px 70px;
  border-color: transparent transparent transparent transparent;
  transition: border-color 0.2s ease;
}
@media only screen and (min-width: 700px ) {
  .ctaBoxItem > a:before {
    width: 100px;
    border-width: 0 0 100px 100px;
  }
}
@media only screen and (min-width: 1025px ) {
  .ctaBoxItem > a:before {
    width: 130px;
    border-width: 0 0 130px 130px;
  }
}
.ctaBoxItem > a:hover:before,
.ctaBoxItem > a:focus:before {
  border-color: transparent transparent #607631 transparent;
}
.ctaBoxItem > a:hover:after,
.ctaBoxItem > a:focus:after {
  color: #CFD82D;
}
.ctaBoxItem > a:hover .header,
.ctaBoxItem > a:focus .header {
  text-decoration: underline;
}
@media only screen and (min-width: 1025px ) {
  .ctaBoxItem > a .thumb {
    padding-bottom: 70px;
  }
}
.ctaBoxItem .inner {
  border: 3px solid #607631;
  display: flex;
  min-height: 190px;
  position: relative;
}
@media only screen and (min-width: 700px ) {
  .ctaBoxItem .inner {
    padding: 1.25rem;
    padding-right: 5rem;
    border-bottom-width: 10px;
    min-height: 290px;
    align-items: center;
  }
}
@media only screen and (min-width: 1025px ) {
  .ctaBoxItem .inner {
    flex-direction: column;
    height: 100%;
    text-align: center;
    padding: 1.75rem;
    min-height: 405px;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1025px ) {
  .ctaBoxItem .inner.hasThumb {
    justify-content: flex-start;
  }
}
.ctaBoxItem .inner .thumb {
  width: 130px;
  flex-grow: 0;
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
}
@media only screen and (min-width: 700px ) {
  .ctaBoxItem .inner .thumb {
    width: 210px;
    height: 210px;
    border-radius: 240px;
    margin-right: 2rem;
  }
}
@media only screen and (min-width: 1025px ) {
  .ctaBoxItem .inner .thumb {
    width: 200px;
    height: 200px;
    border-radius: 240px;
    margin-bottom: 1.25rem;
    margin-right: 0;
  }
}
.ctaBoxItem .inner .preview {
  padding: 1.5rem 3.25rem 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (min-width: 700px ) {
  .ctaBoxItem .inner .preview {
    padding: 0;
    flex-direction: row;
    align-items: center;
  }
}
@media only screen and (min-width: 1025px ) {
  .ctaBoxItem .inner .preview {
    flex-direction: column;
    padding: 0;
    font-size: 1rem;
  }
}
@media only screen and (min-width: 700px ) {
  .ctaBoxItem .inner .category {
    width: 100%;
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
  }
}
@media only screen and (min-width: 1025px ) {
  .ctaBoxItem .inner .category {
    position: static;
    margin-bottom: 1.25rem;
  }
}
.ctaBoxItem .inner .header {
  display: block;
  margin: 0 0 1rem;
}
@media only screen and (min-width: 700px ) {
  .ctaBoxItem .inner .header {
    padding-right: 2rem;
  }
}
@media only screen and (min-width: 1025px ) {
  .ctaBoxItem .inner .header {
    padding-right: 0;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 700px ) {
  .ctaBoxItem .inner .text {
    margin: 0;
  }
}
/* EVENT LISTINGS
-----------------------------------------------*/
/*INTERIOR EVENTS WITHOUT CALENDAR
------------------------*/
.imodcmscalendar.listing-view .CalendarFilters {
  background: #F6F6F6;
  margin: 20px 0;
  padding: 20px;
}
.imodcmscalendar.listing-view .CalendarFilters label {
  margin: 0 10px 0 0;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.85em;
  letter-spacing: 0.5px;
}
.imodcmscalendar.listing-view .CalendarFilters select {
  max-width: 100%;
}
.intEventItem {
  border: none;
  padding-top: 15px;
  padding-bottom: 40px;
  position: relative;
  clear: both;
  float: left;
  width: 100%;
}
@media only screen and (min-width: 700px ) {
}
.intEventItem + .intEventItem {
  padding-top: 40px;
  border-top: 1px solid #D8D8D8;
}
.intEventItem .thumb {
  float: right;
  width: 180px;
  margin-left: 30px;
  margin-bottom: 10px;
  background-size: cover;
  display: none;
}
@media only screen and (min-width: 700px ) {
  .intEventItem .thumb {
    display: block;
  }
}
.intEventItem .date {
  float: left;
  width: 75px;
  height: 85px;
  border: 2px solid #000;
  background: #000;
  text-align: center;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
}
@media only screen and (min-width: 1025px ) {
  .intEventItem .date {
    width: 95px;
  }
}
.intEventItem .date .month {
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1;
  font-weight: bold;
  color: #CFD82D;
}
.intEventItem .date .day {
  font-size: 2rem;
  line-height: 1;
  margin-top: 3px;
  font-weight: 500;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.intEventItem .text {
  padding-left: 95px;
}
@media only screen and (min-width: 1025px ) {
  .intEventItem .text {
    padding-left: 135px;
  }
}
.intEventItem .text .title {
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 1.3;
  margin: 0 0 7px;
  color: #607631;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.intEventItem .text .title a {
  color: inherit;
  text-decoration: none;
}
.intEventItem .text .title a:hover,
.intEventItem .text .title a:focus {
  text-decoration: underline;
}
.intEventItem .text .preview {
  margin: 10px 0 0;
}
@media only screen and (min-width: 1025px ) {
  .intEventItem .text .preview {
    font-size: 1rem;
  }
}
.intEventItem .text .locationTime {
  color: #58595B;
  font-size: 0.825rem;
}
.intEventItem .text .locationTime .location {
  display: block;
  line-height: normal;
  margin: 2px 0;
}
@media only screen and (min-width: 700px ) {
  .intEventItem .text .locationTime .location {
    display: inline-block;
  }
}
.intEventItem .text .locationTime .location:before {
  font-family: "Font Awesome 5 Free";
  content: "\f041";
  padding-right: 5px;
  font-weight: bold;
  width: 20px;
  text-align: center;
  display: inline-block;
}
.intEventItem .text .locationTime .timeRange {
  display: block;
  margin: 2px 0;
}
@media only screen and (min-width: 700px ) {
  .intEventItem .text .locationTime .timeRange {
    display: inline-block;
  }
}
.intEventItem .text .locationTime .timeRange:before {
  font-family: "Font Awesome 5 Free";
  content: "\f017";
  padding-right: 5px;
  font-weight: bold;
  width: 20px;
  display: inline-block;
}
@media only screen and (min-width: 700px ) {
  .intEventItem.withThumb .text {
    padding-right: 210px;
    display: block;
  }
}
/* FEATURE ROTATOR
---------------------------------------------*/
#heroSlider {
  position: relative;
}
#heroSlider .slidesWrap {
  position: relative;
  background: #000;
  margin: 0;
}
#heroSlider .pagerOuter {
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  z-index: 3;
}
#heroSlider .pagerOuter #pagerWrap .slick-dots {
  position: static;
}
#heroSlider .pagerOuter #pagerWrap ul {
  display: flex;
}
#heroSlider .pagerOuter #pagerWrap ul li {
  width: 100%;
  margin: 0;
}
#heroSlider .pagerOuter #pagerWrap ul li + li {
  margin-left: 1.5rem;
}
#heroSlider .pagerOuter #pagerWrap ul li button {
  height: 5px;
  width: 100%;
  display: block;
  margin: 0;
  background: rgba(207, 205, 205, 0.75);
}
#heroSlider .pagerOuter #pagerWrap ul li button .slick-dot-icon {
  display: none;
}
#heroSlider .pagerOuter #pagerWrap ul li button:focus {
  outline: 1px solid #fff;
  outline-offset: 3px;
}
#heroSlider .pagerOuter #pagerWrap ul li.slick-active button {
  background-color: #CFD82D;
}
#heroSlider .slideItem {
  position: relative;
  display: block!important;
}
#heroSlider .slideItem .sldImg {
  position: relative;
  z-index: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 485px;
}
@media only screen and (min-width: 700px ) {
  #heroSlider .slideItem .sldImg {
    height: 450px;
  }
}
@media only screen and (min-width: 1025px ) {
  #heroSlider .slideItem .sldImg {
    height: auto;
    min-height: 600px;
  }
}
#heroSlider .slideItem .sldImg img {
  visibility: hidden;
}
#heroSlider .slideItem .heroText {
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  width: 100%;
}
@media only screen and (min-width: 700px ) {
  #heroSlider .slideItem .heroText {
    height: 100%;
    bottom: 0;
  }
}
@media only screen and (min-width: 700px ) {
  #heroSlider .slideItem .heroText .container {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
#heroSlider .slideItem .heroText .row {
  position: relative;
  width: 100%;
}
@media only screen and (min-width: 700px ) {
}
@media only screen and (min-width: 700px ) {
  #heroSlider .slideItem .heroText .heroTextInner {
    padding-right: 6%;
  }
}
#heroSlider .slideItem .heroText .title {
  font-size: 1.875rem;
  line-height: 1.2;
  color: #fff;
  display: block;
  margin: 0 0 1.5rem;
  text-align: left;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
}
@media only screen and (min-width: 700px) and (max-width:  1024px ) {
  #heroSlider .slideItem .heroText .title {
    font-size: 2.5rem;
  }
}
@media only screen and (min-width: 1025px ) {
  #heroSlider .slideItem .heroText .title {
    font-size: 4rem;
    line-height: 1.25;
  }
}
#heroSlider .slideItem .heroText .title a {
  text-decoration: none;
  color: inherit;
  display: block;
}
#heroSlider .slideItem .heroText .title a:hover,
#heroSlider .slideItem .heroText .title a:focus {
  text-decoration: underline;
}
#heroSlider .slideItem .heroText .title a:focus {
  outline-offset: 2px;
  outline: solid 3px rgba(65, 166, 226, 0.5);
}
#heroSlider .slideItem .heroText .preview {
  font-size: 1rem;
  line-height: 1.4;
  display: block;
  margin: 10px 0 0px;
  color: #fff;
  font-weight: bold;
}
@media only screen and (min-width: 1025px ) {
  #heroSlider .slideItem .heroText .preview {
    font-size: 1.25rem;
    line-height: 1.4;
    margin-top: 20px;
  }
}
#heroSlider .slideItem .heroText .buttonRow {
  margin-top: 1rem;
}
@media only screen and (min-width: 1025px ) {
  #heroSlider .slideItem .heroText .buttonRow {
    margin-top: 2.5rem;
  }
}
#heroSlider .slideItem .heroText .buttonRow .button {
  padding: 0.5rem 1rem;
}
#heroSlider .slideItem .heroText .buttonRow .button + #heroSlider .slideItem .heroText .buttonRow .button {
  margin-left: 2rem;
}
@media (min-width: 1200px) {
  #heroSlider #arrowsWrap {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
  }
}
#heroSlider #arrowsWrap .arwWrap {
  position: absolute;
  top: 50%;
}
#heroSlider #arrowsWrap .arwWrap.arrow-prev {
  left: 0px;
}
@media only screen and (min-width: 700px ) {
  #heroSlider #arrowsWrap .arwWrap.arrow-prev {
    left: 15px;
  }
}
@media (min-width: 1200px) {
  #heroSlider #arrowsWrap .arwWrap.arrow-prev {
    left: -580px;
  }
}
@media (min-width: 1400px) {
  #heroSlider #arrowsWrap .arwWrap.arrow-prev {
    left: -620px;
  }
}
#heroSlider #arrowsWrap .arwWrap.arrow-next {
  right: 0px;
}
@media only screen and (min-width: 700px ) {
  #heroSlider #arrowsWrap .arwWrap.arrow-next {
    right: 15px;
  }
}
@media (min-width: 1200px) {
  #heroSlider #arrowsWrap .arwWrap.arrow-next {
    left: 580px;
  }
}
@media (min-width: 1400px) {
  #heroSlider #arrowsWrap .arwWrap.arrow-next {
    left: 620px;
  }
}
#heroSlider #arrowsWrap .arwWrap button {
  color: #fff;
  font-size: 2rem;
  line-height: 1;
  width: 50px;
  height: 50px;
  padding: 0;
}
@above-sm {
  font-size: 2.5rem;
}
#heroSlider #arrowsWrap .arwWrap button:hover,
#heroSlider #arrowsWrap .arwWrap button:focus {
  background: rgba(0, 0, 0, 0.5);
}
#heroSlider #arrowsWrap .arwWrap button:focus {
  top: 0;
  outline: 2px solid #fff;
}
#heroSlider #arrowsWrap .arwWrap .slick-prev {
  left: 0;
}
#heroSlider #arrowsWrap .arwWrap .slick-next {
  right: 0;
}
#heroSlider .controlsOuter {
  position: absolute;
  right: 0;
  bottom: 0.5rem;
}
@media only screen and (min-width: 700px ) {
  #heroSlider .controlsOuter {
    left: 0;
    right: auto;
    width: 100%;
  }
}
@media only screen and (min-width: 1025px ) {
  #heroSlider .controlsOuter {
    bottom: 1.5rem;
  }
}
#heroSlider #controlsWrap button {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 0.75rem;
  line-height: 1;
  padding: 0;
  width: 3rem;
  height: 3rem;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin-left: -1rem;
}
@media only screen and (min-width: 1025px ) {
  #heroSlider #controlsWrap button {
    font-size: 1.5rem;
  }
}
#heroSlider #controlsWrap button:hover,
#heroSlider #controlsWrap button:focus {
  background: rgba(0, 0, 0, 0.5);
}
#heroSlider #controlsWrap button:focus {
  top: 0;
  outline: 2px solid #fff;
}
#heroSlider #controlsWrap button .slick-pause-icon:before {
  content: "\f04c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
/* SIDEBAR MENU */
.sideBar .secondaryNav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sideBar .secondaryNav ul li a {
  display: block;
  text-decoration: none;
  font-weight: bold;
}
.sideBar .secondaryNav > ul {
  margin: 0 0 3rem;
}
.sideBar .secondaryNav > ul > li + li {
  border-top: 1px solid #D8D8D8;
}
.sideBar .secondaryNav > ul > li > a {
  display: block;
  padding: 10px 10px;
}
.sideBar .secondaryNav > ul > li > a:hover,
.sideBar .secondaryNav > ul > li > a:focus {
  background: rgba(51, 51, 51, 0.05);
  text-decoration: underline;
}
.sideBar .secondaryNav > ul > li > a:focus {
  text-decoration: underline;
}
.sideBar .secondaryNav > ul > li.selected {
  border: none;
}
.sideBar .secondaryNav > ul > li.selected > a {
  background: #607631;
  color: #fff;
}
.sideBar .secondaryNav > ul > li > ul {
  margin-bottom: 10px;
}
.sideBar .secondaryNav > ul > li > ul > li > a {
  padding: 5px 10px 5px 26px;
  border-left: 4px solid transparent;
}
.sideBar .secondaryNav > ul > li > ul > li > a:hover,
.sideBar .secondaryNav > ul > li > ul > li > a:focus {
  text-decoration: underline;
}
.sideBar .secondaryNav > ul > li > ul > li.selected a {
  border-color: #607631;
}
.sideBar aside + aside {
  margin-top: 40px;
}
/* FOOTER ELEMENTS
---------------------------------------------*/
footer {
  float: left;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 300;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 2.125rem 0;
  background: #000;
  color: #fff;
}
@media only screen and (min-width: 700px ) {
  footer {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 4rem;
    padding-bottom: 2.25rem;
  }
}
@media only screen and (min-width: 1025px ) {
  footer {
    padding-left: 0;
    padding-right: 0;
    padding-top: 5rem;
    padding-bottom: 4.5rem;
  }
}
footer h2,
footer h3 {
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.125rem;
  line-height: 1;
  margin: 0 0 1rem;
}
footer a {
  color: inherit;
  text-decoration: underline;
}
footer a:hover,
footer a:focus {
  text-decoration: none;
  color: inherit;
}
footer a:focus {
  outline: 1px solid #fff;
  outline-offset: 3px;
}
footer address {
  line-height: 2;
  margin: 0;
}
footer .footRight {
  text-align: right;
}
footer .navLinksWrap {
  margin-bottom: 3rem;
  margin-top: 3rem;
}
@media only screen and (min-width: 700px ) {
  footer .navLinksWrap {
    margin-top: 0;
    margin-bottom: 2.5rem;
  }
}
@media only screen and (min-width: 1025px ) {
  footer .navLinksWrap {
    margin-bottom: 4.5rem;
  }
}
footer .navLiks {
  margin-bottom: 1.875rem;
}
footer .navLiks ul {
  margin: 0;
  list-style: none;
  padding: 0;
  line-height: 2;
}
footer .navLiks ul li a {
  text-decoration: none;
  display: inline-block;
}
footer .navLiks ul li a:hover,
footer .navLiks ul li a:focus {
  color: #CFD82D;
  text-decoration: underline;
}
footer .footlogo {
  text-align: center;
}
@media only screen and (min-width: 700px ) {
  footer .footlogo {
    text-align: left;
  }
}
footer .footlogo img {
  max-height: 65px;
}
footer .footCTA {
  text-align: center;
}
@media only screen and (min-width: 1025px ) {
  footer .footCTA {
    text-align: left;
  }
}
footer .button {
  margin: 0;
  background: #CFD82D;
  color: #000;
  border-radius: 0;
  font-size: 1.0625rem;
  line-height: 1;
  font-weight: 400;
  letter-spacing: normal;
  border-color: transparent;
  padding: 0.875rem 1rem;
}
@media only screen and (min-width: 700px ) {
  footer .button {
    min-width: 160px;
  }
}
footer .button:hover,
footer .button:focus {
  border-color: #fff;
  color: #CFD82D;
  background: #000;
}
footer .button:focus {
  outline: none;
}
footer ul.social {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  line-height: 1;
}
@media only screen and (min-width: 700px ) {
  footer ul.social {
    justify-content: space-between;
    margin-top: 0;
  }
}
@media only screen and (min-width: 1025px ) {
  footer ul.social {
    max-width: 200px;
  }
}
footer ul.social li {
  display: inline-block;
  padding: 0px;
}
footer ul.social li + li {
  margin-left: 0.5rem;
}
footer ul.social li a {
  color: #CFD82D;
  display: block;
  padding: 5px;
}
footer ul.social li a svg {
  height: 16px;
  display: inline-block;
  fill: white;
  margin-top: -2px;
}
footer ul.social li a:hover,
footer ul.social li a:focus {
  color: #fff;
}
footer ul.social li a:hover svg,
footer ul.social li a:focus svg {
  fill: #333;
}
footer ul.social li a:focus {
  outline: 1px solid #fff;
}
footer ul.social + h3 {
  margin-top: 2rem;
}
footer ul.social.dark li a {
  color: #607631;
}
footer ul.social.dark li a:hover,
footer ul.social.dark li a:focus {
  color: #fff;
}
footer .mobileSocial {
  text-align: center;
  margin-top: 2rem;
}
footer .ctaBtn {
  text-align: center;
}
@media only screen and (min-width: 700px ) {
  footer .ctaBtn {
    text-align: right;
  }
}
footer .ctaBtn .button {
  margin: 0;
  width: 80%;
  max-width: 210px;
}
@media only screen and (min-width: 1025px ) {
  footer .ctaBtn .button {
    width: 100%;
    margin-top: 0;
  }
}
footer .ctaBtn .button .fas {
  margin-left: 3px;
}
@media only screen and (min-width: 700px ) {
  footer .subFooter .subFooterWrap {
    display: flex;
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 1025px ) {
  footer .subFooter .subFooterWrap {
    font-size: 0.875rem;
    margin-top: 2.5rem;
  }
}
footer .subFooter .legal ul {
  padding: 0;
  list-style: none;
  margin: 1rem 0 0;
  display: flex;
  justify-content: space-around;
}
@media only screen and (min-width: 700px ) {
  footer .subFooter .legal ul {
    justify-content: flex-start;
    margin: 0 2rem 0 0;
  }
}
@media only screen and (min-width: 700px ) {
  footer .subFooter .legal ul li + li {
    margin-left: 2rem;
  }
}
footer .subFooter .legal ul li a {
  display: block;
  text-align: center;
  text-decoration: none;
  padding: 1rem 0.5rem;
}
@media only screen and (min-width: 700px ) {
  footer .subFooter .legal ul li a {
    padding: 0;
  }
}
footer .subFooter .legal ul li a:hover,
footer .subFooter .legal ul li a:focus {
  text-decoration: underline;
  color: #CFD82D;
}
footer .subFooter .copyright {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: normal;
  margin-top: 0;
  font-size: 0.75rem;
  text-align: center;
}
@media only screen and (min-width: 700px ) {
  footer .subFooter .copyright {
    text-align: left;
    margin-top: 0;
    font-size: 1rem;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1025px ) {
  footer .subFooter .copyright {
    margin-top: 0;
    font-size: 0.875rem;
  }
}
/* MOBILE NAVIGATION
-----------------------------------------------*/
body.openMenu {
  overflow: hidden;
}
body.openMenu #off-canvas {
  flex-grow: 2;
  top: 0;
}
#off-canvas {
  display: none;
  width: 100%;
  top: -100%;
  background: #F4F4F4;
  overflow-y: auto;
  z-index: 4;
  position: relative;
  left: 0;
  height: 0;
  transition: top 1s ease;
}
#off-canvas .c-offcanvas__inner {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
}
.mobileCloseBtn {
  height: 75px;
  position: absolute;
  top: 10px;
  right: 15px;
  display: flex;
  align-items: center;
}
#js-offcanvas-close {
  height: 45px;
  width: 40px;
  background: #fff;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1;
  padding: 0;
  outline-offset: -2px;
  transition: all .2s ease;
}
#off-canvas h2 {
  color: #fff;
}
#off-canvas ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#off-canvas ul li a {
  display: block;
  text-decoration: none;
}
#off-canvas ul li a:focus {
  outline: 2px solid rgba(0, 117, 154, 0.5);
  text-decoration: underline;
}
#off-canvas .mainNav {
  padding: 15px;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
  flex-grow: 1;
  overflow: scroll;
}
@media only screen and (min-width: 700px ) {
  #off-canvas .mainNav {
    padding-left: 40px;
    padding-right: 40px;
  }
}
#off-canvas .mainNav .accordion-btn-wrap {
  background: none;
  border: none;
  color: #000;
  padding: 0 0 0;
  min-height: 45px;
}
#off-canvas .mainNav .accordion-btn-wrap:focus {
  outline: 2px solid rgba(0, 117, 154, 0.75);
  outline-offset: -2px;
}
#off-canvas .mainNav .accordion-btn-wrap.accordion-active {
  color: #607631;
}
#off-canvas .mainNav > ul > li.has-subnav {
  padding-left: 0;
}
#off-canvas .mainNav > ul > li > a {
  font-size: 1rem;
  font-weight: 500;
  padding: 10px 10px 10px 0;
  color: #000;
  text-transform: uppercase;
}
#off-canvas .mainNav > ul > li.active > a {
  color: #607631;
}
#off-canvas .mainNav > ul > li.hideMobile {
  display: none;
}
@media only screen and (min-width: 1025px ) {
  #off-canvas .mainNav > ul > li.hideMobile {
    display: block;
  }
}
#off-canvas .mainNav > ul > li > ul {
  padding-bottom: 1rem;
  border-top: 0.5rem solid #607631;
  position: relative;
  padding-top: 0.5rem;
}
#off-canvas .mainNav > ul > li > ul:before {
  content: "";
  position: absolute;
  top: 0;
  left: 1rem;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 6.5px 0 6.5px;
  border-color: #607631 transparent transparent transparent;
}
#off-canvas .mainNav > ul > li > ul > li > a {
  font-size: 0.9375rem;
  line-height: normal;
  font-weight: 300;
  color: #000;
  padding: 0.5rem 0;
}
#off-canvas .mainNav > ul > li > ul > li.has-subnav > a {
  padding-left: 0;
}
#off-canvas .mainNav > ul > li > ul > li > ul {
  background: rgba(255, 255, 255, 0.2);
  border-top: 2px solid #607631;
}
#off-canvas .mainNav > ul > li > ul > li > ul > li > a {
  font-size: 0.875rem;
  line-height: normal;
  padding: 5px 0px 5px 35px;
  font-weight: bold;
  color: #fff;
}
#off-canvas .mainNav > ul > li > ul > li > ul > li.has-subnav > a {
  padding-left: 0;
}
#off-canvas .mobileMemberTools {
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
#off-canvas .mobileMemberTools ul {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (min-width: 700px ) {
  #off-canvas .mobileMemberTools ul {
    flex-wrap: nowrap;
  }
}
#off-canvas .mobileMemberTools ul li {
  width: 50%;
}
#off-canvas .mobileMemberTools ul li:nth-child(odd) {
  border-right: 1px solid #fff;
  border-top: 2px solid #fff;
}
@media only screen and (min-width: 700px ) {
  #off-canvas .mobileMemberTools ul li:nth-child(odd) {
    border-right: none;
    border-top: none;
  }
}
#off-canvas .mobileMemberTools ul li:nth-child(even) {
  border-left: 1px solid #fff;
}
@media only screen and (min-width: 700px ) {
  #off-canvas .mobileMemberTools ul li:nth-child(even) {
    border-left: none;
  }
}
#off-canvas .mobileMemberTools ul li:nth-child(1) {
  border-top: none;
}
#off-canvas .mobileMemberTools ul li:nth-child(n+4) {
  border-top: 2px solid #fff;
}
@media only screen and (min-width: 700px ) {
  #off-canvas .mobileMemberTools ul li:nth-child(n+4) {
    border-top: none;
  }
}
@media only screen and (min-width: 700px ) {
  #off-canvas .mobileMemberTools ul li + li {
    border-left: 2px solid #fff !important;
  }
}
#off-canvas .mobileMemberTools ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  color: #fff;
  text-transform: uppercase;
  padding: 7px 0;
  text-align: center;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: normal;
}
#off-canvas .mobileMemberTools ul li a:hover,
#off-canvas .mobileMemberTools ul li a:focus {
  text-decoration: underline;
}
#off-canvas .mobileMemberTools ul li.green > a {
  background: #607631;
}
#off-canvas .mobileMemberTools ul li.homeBtn > a {
  background: #CFD82D;
  color: #607631;
}
#off-canvas .mobileMemberTools ul li.homeBtn > a:before {
  content: "";
  background-image: url('images/btn_homelogo.svg');
  width: 18px;
  height: 18px;
  display: inline-block;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-right: 5px;
}
/* FORM STYLING 
---------------------------------------------*/
/* Input Mixin */
.input_styles {
  background: #fff;
  border: 3px solid #CFD82D;
  border-radius: 0;
  box-shadow: none;
  filter: none;
  padding: 0px 10px;
  width: 100%;
  max-width: 100%;
  font-size: 1rem;
}
.input_styles:focus {
  outline: none;
  border-color: #000;
}
@media only screen and (max-width:  1024px ) {
  .input_styles {
    width: 100%;
  }
}
#main div[id*="pnlBreadcrumbs"],
#main div.idbmsBreadcrumbWrapper {
  display: block;
  padding: 0 0 5px;
  margin: 0 0 30px;
  border-bottom: 4px solid #607631;
}
@media only screen and (min-width: 700px ) {
  #main div[id*="pnlBreadcrumbs"],
  #main div.idbmsBreadcrumbWrapper {
    border-bottom: none;
    padding-bottom: 0;
  }
  #main div[id*="pnlBreadcrumbs"] > * + *,
  #main div.idbmsBreadcrumbWrapper > * + * {
    border-left: 2px solid #E0E0E0;
    margin: 0 0px 0 0px;
    display: inline-block;
  }
  #main div[id*="pnlBreadcrumbs"] > * + * .bcInner,
  #main div.idbmsBreadcrumbWrapper > * + * .bcInner {
    margin-left: 15px;
    position: relative;
  }
}
#main div[id*="pnlBreadcrumbs"] .idbmsBreadcrumbSeparator,
#main div.idbmsBreadcrumbWrapper .idbmsBreadcrumbSeparator {
  display: none;
}
#main div[id*="pnlBreadcrumbs"] .idbmsBreadcrumb,
#main div.idbmsBreadcrumbWrapper .idbmsBreadcrumb,
#main div[id*="pnlBreadcrumbs"] .idbmsBreadcrumbActive,
#main div.idbmsBreadcrumbWrapper .idbmsBreadcrumbActive {
  color: #58595B;
  padding: 3px 0px;
  font-size: 0.9375rem;
  line-height: normal;
  font-weight: bold;
  display: none;
}
@media only screen and (min-width: 700px ) {
  #main div[id*="pnlBreadcrumbs"] .idbmsBreadcrumb .stepTxt,
  #main div.idbmsBreadcrumbWrapper .idbmsBreadcrumb .stepTxt,
  #main div[id*="pnlBreadcrumbs"] .idbmsBreadcrumbActive .stepTxt,
  #main div.idbmsBreadcrumbWrapper .idbmsBreadcrumbActive .stepTxt {
    display: none;
  }
}
@media only screen and (min-width: 700px ) {
  #main div[id*="pnlBreadcrumbs"] .idbmsBreadcrumb,
  #main div.idbmsBreadcrumbWrapper .idbmsBreadcrumb,
  #main div[id*="pnlBreadcrumbs"] .idbmsBreadcrumbActive,
  #main div.idbmsBreadcrumbWrapper .idbmsBreadcrumbActive {
    margin-right: 11px;
  }
}
@media only screen and (min-width: 1025px ) {
  #main div[id*="pnlBreadcrumbs"] .idbmsBreadcrumb,
  #main div.idbmsBreadcrumbWrapper .idbmsBreadcrumb,
  #main div[id*="pnlBreadcrumbs"] .idbmsBreadcrumbActive,
  #main div.idbmsBreadcrumbWrapper .idbmsBreadcrumbActive {
    font-size: 1rem;
  }
}
#main div[id*="pnlBreadcrumbs"] .idbmsBreadcrumbActive,
#main div.idbmsBreadcrumbWrapper .idbmsBreadcrumbActive {
  font-size: 0.9375rem;
  width: 100%;
  display: block;
  float: none;
}
#main div[id*="pnlBreadcrumbs"] .idbmsBreadcrumbActive .stepTxt,
#main div.idbmsBreadcrumbWrapper .idbmsBreadcrumbActive .stepTxt {
  display: inline-lock;
}
@media only screen and (min-width: 700px ) {
  #main div[id*="pnlBreadcrumbs"] .idbmsBreadcrumbActive .stepTxt,
  #main div.idbmsBreadcrumbWrapper .idbmsBreadcrumbActive .stepTxt {
    display: none;
  }
}
@media only screen and (min-width: 700px ) {
  #main div[id*="pnlBreadcrumbs"] .idbmsBreadcrumbActive,
  #main div.idbmsBreadcrumbWrapper .idbmsBreadcrumbActive {
    width: auto;
    display: inline-block;
    position: relative;
  }
  #main div[id*="pnlBreadcrumbs"] .idbmsBreadcrumbActive .bcInner:after,
  #main div.idbmsBreadcrumbWrapper .idbmsBreadcrumbActive .bcInner:after {
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    content: "";
    background-color: #607631;
    height: 3px;
  }
}
@media only screen and (min-width: 1025px ) {
  #main div[id*="pnlBreadcrumbs"] .idbmsBreadcrumbActive,
  #main div.idbmsBreadcrumbWrapper .idbmsBreadcrumbActive {
    font-size: 1rem;
  }
}
#main div[id*="pnlBreadcrumbs"] .idbmsBreadcrumbLink,
#main div.idbmsBreadcrumbWrapper .idbmsBreadcrumbLink {
  display: none;
}
@media only screen and (min-width: 700px ) {
  #main div[id*="pnlBreadcrumbs"] .idbmsBreadcrumbLink,
  #main div.idbmsBreadcrumbWrapper .idbmsBreadcrumbLink {
    display: inline-block;
  }
}
#main div[id*="pnlBreadcrumbs"] .idbmsBreadcrumb,
#main div.idbmsBreadcrumbWrapper .idbmsBreadcrumb {
  display: none;
}
@media only screen and (min-width: 700px ) {
  #main div[id*="pnlBreadcrumbs"] .idbmsBreadcrumb,
  #main div.idbmsBreadcrumbWrapper .idbmsBreadcrumb {
    display: inline-block;
  }
}
#main div[id*="pnlBreadcrumbs"] .stepTxt,
#main div.idbmsBreadcrumbWrapper .stepTxt {
  padding-right: 5px;
  font-weight: bold;
}
@media only screen and (min-width: 700px ) {
  #main div[id*="pnlBreadcrumbs"] .stepTxt,
  #main div.idbmsBreadcrumbWrapper .stepTxt {
    display: none;
  }
}
#main nav[id*='FormProgressIndicatorWrapper'] {
  padding: 0;
}
#main nav[id*='FormProgressIndicatorWrapper'] ol {
  margin: 0 0 30px;
}
#main nav[id*='FormProgressIndicatorWrapper'] ol li {
  display: none;
}
@media only screen and (min-width: 700px ) {
  #main nav[id*='FormProgressIndicatorWrapper'] ol li {
    display: inline-block;
  }
}
@media only screen and (min-width: 700px ) {
  #main nav[id*='FormProgressIndicatorWrapper'] ol li + li:before {
    content: "";
    margin: 0 10px 0 6px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M6 0l12 12-12 12z'/%3E%3C/svg%3E");
    width: 10px;
    height: 10px;
    display: inline-block;
    background-size: contain;
    opacity: 0.25;
  }
}
#main nav[id*='FormProgressIndicatorWrapper'] ol li.current {
  display: block;
  color: #607631;
  font-weight: 900;
}
@media only screen and (min-width: 700px ) {
  #main nav[id*='FormProgressIndicatorWrapper'] ol li.current {
    display: inline-block;
  }
}
@media only screen and (min-width: 700px ) {
  #main nav[id*='FormProgressIndicatorWrapper'] ol li .stepTxt {
    display: none;
  }
}
#main .imod_formItemWrapper .EventsHeader,
#main .imod_fieldWrapper .EventsHeader {
  background: #000;
  color: #fff;
  border: none;
  font-size: 1.125rem;
  line-height: normal;
  font-weight: 500;
  padding: 15px 15px 15px 15px;
  text-transform: none;
  position: relative;
  display: block;
  margin-bottom: 10px;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}
#main .imod_formItemWrapper .EventsHeader a,
#main .imod_fieldWrapper .EventsHeader a {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
#main .imod_formItemWrapper .EventsHeader a:focus,
#main .imod_fieldWrapper .EventsHeader a:focus {
  box-shadow: 0 0 0px 3px rgba(0, 117, 154, 0.5);
}
#main .imod_formItemWrapper .EventsHeader a .categoryToggle,
#main .imod_fieldWrapper .EventsHeader a .categoryToggle {
  display: block;
  float: right;
  padding: 7px 15px;
}
#main .imod_formItemWrapper .EventsHeader a .givingExpand,
#main .imod_fieldWrapper .EventsHeader a .givingExpand {
  background: transparent!important;
  color: #fff;
  padding: 10px;
  text-align: center;
  width: 50px;
}
#main .imod_categoryWrapper.stacked .imod_fieldLabel {
  padding-bottom: 5px;
}
#main .imod_fieldWrapper {
  border: none;
  padding: 10px 0;
}
#main .imod_fieldWrapper.headerWrap {
  padding-top: 0;
}
#main .imod_fieldWrapper.stacked .imod_fieldLabel {
  padding-bottom: 5px;
}
#main .imod_fieldWrapper.checkboxLeft {
  padding-left: 22px;
}
#main .imod_fieldWrapper.checkboxLeft .imod_fieldInput {
  top: 10px;
}
@media only screen and (min-width: 700px ) {
  #main .imod_fieldWrapper.checkboxLeft .imod_fieldInput {
    top: 6px;
  }
}
#main .imod_fieldWrapper .imod_fieldLabel {
  font-size: 0.75rem;
  font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #607631;
  text-transform: uppercase;
  font-weight: 500;
}
#main .imod_fieldWrapper .imod_fieldLabel label {
  margin: 0;
}
#main .imod_fieldWrapper .imod_fieldInput textarea,
#main .imod_fieldWrapper .imod_fieldInput input[type="text"],
#main .imod_fieldWrapper .imod_fieldInput select {
  background: #fff;
  border: 3px solid #CFD82D;
  border-radius: 0;
  box-shadow: none;
  filter: none;
  padding: 0px 10px;
  width: 100%;
  max-width: 100%;
  font-size: 1rem;
}
#main .imod_fieldWrapper .imod_fieldInput textarea:focus,
#main .imod_fieldWrapper .imod_fieldInput input[type="text"]:focus,
#main .imod_fieldWrapper .imod_fieldInput select:focus {
  outline: none;
  border-color: #000;
}
@media only screen and (max-width:  1024px ) {
  #main .imod_fieldWrapper .imod_fieldInput textarea,
  #main .imod_fieldWrapper .imod_fieldInput input[type="text"],
  #main .imod_fieldWrapper .imod_fieldInput select {
    width: 100%;
  }
}
#main .imod_fieldWrapper .imod_fieldInput input[type="text"] {
  height: 50px;
}
#main .imod_fieldWrapper .imod_fieldInput select[id*='DropDown1'],
#main .imod_fieldWrapper .imod_fieldInput select[id*='ddHowOften'],
#main .imod_fieldWrapper .imod_fieldInput select[id*='ddStartDay'],
#main .imod_fieldWrapper .imod_fieldInput select[id*='ddNumPayments'] {
  height: 50px;
}
#main .imod_fieldWrapper .imod_fieldInput select[id*='DropDown1'] option,
#main .imod_fieldWrapper .imod_fieldInput select[id*='ddHowOften'] option,
#main .imod_fieldWrapper .imod_fieldInput select[id*='ddStartDay'] option,
#main .imod_fieldWrapper .imod_fieldInput select[id*='ddNumPayments'] option {
  height: 50px;
}
#main .imod_fieldWrapper .imod_fieldInput .divAdvancedDesignations input[type="text"] {
  width: auto;
}
#main .imod_fieldWrapper .imod_fieldInput label {
  padding-top: 0;
}
#main .imod_fieldWrapper .imod_col2 {
  float: left;
  width: 100%;
}
@media only screen and (min-width: 700px ) {
  #main .imod_fieldWrapper .imod_col2 {
    width: 70%;
  }
}
#main .imod_fieldWrapper .imod_col2 textarea,
#main .imod_fieldWrapper .imod_col2 input[type="text"],
#main .imod_fieldWrapper .imod_col2 select {
  background: #fff;
  border: 3px solid #CFD82D;
  border-radius: 0;
  box-shadow: none;
  filter: none;
  padding: 0px 10px;
  width: 100%;
  max-width: 100%;
  font-size: 1rem;
}
#main .imod_fieldWrapper .imod_col2 textarea:focus,
#main .imod_fieldWrapper .imod_col2 input[type="text"]:focus,
#main .imod_fieldWrapper .imod_col2 select:focus {
  outline: none;
  border-color: #000;
}
@media only screen and (max-width:  1024px ) {
  #main .imod_fieldWrapper .imod_col2 textarea,
  #main .imod_fieldWrapper .imod_col2 input[type="text"],
  #main .imod_fieldWrapper .imod_col2 select {
    width: 100%;
  }
}
#main .imod_fieldWrapper .imod_col2 input[type="text"] {
  height: 50px;
}
#main .imod_fieldWrapper .imod_col2 select[id*='DropDown1'] {
  height: 50px;
}
#main .imod_fieldWrapper .imod_col2 select[id*='DropDown1'] option {
  height: 50px;
}
#main .imod_fieldWrapper .imod_col3 {
  float: left;
  width: 100%;
}
@media only screen and (min-width: 700px ) {
  #main .imod_fieldWrapper .imod_col3 {
    width: 70%;
  }
}
#main .imod_fieldWrapper .imod_col3 textarea,
#main .imod_fieldWrapper .imod_col3 input[type="text"],
#main .imod_fieldWrapper .imod_col3 select {
  background: #fff;
  border: 3px solid #CFD82D;
  border-radius: 0;
  box-shadow: none;
  filter: none;
  padding: 0px 10px;
  width: 100%;
  max-width: 100%;
  font-size: 1rem;
}
#main .imod_fieldWrapper .imod_col3 textarea:focus,
#main .imod_fieldWrapper .imod_col3 input[type="text"]:focus,
#main .imod_fieldWrapper .imod_col3 select:focus {
  outline: none;
  border-color: #000;
}
@media only screen and (max-width:  1024px ) {
  #main .imod_fieldWrapper .imod_col3 textarea,
  #main .imod_fieldWrapper .imod_col3 input[type="text"],
  #main .imod_fieldWrapper .imod_col3 select {
    width: 100%;
  }
}
#main .imod_fieldWrapper .imod_col3 input[type="text"] {
  height: 50px;
}
#main .imod_fieldWrapper .imod_col3 select[id*='DropDown1'] {
  height: 50px;
}
#main .imod_fieldWrapper .imod_col3 select[id*='DropDown1'] option {
  height: 50px;
}
#main .imod_fieldWrapper .imod_col4 {
  width: 100%;
}
@media only screen and (min-width: 700px ) {
  #main .imod_fieldWrapper .imod_col4 {
    width: 70%;
  }
}
#main .imod_fieldWrapper .imod_col4 textarea,
#main .imod_fieldWrapper .imod_col4 input[type="text"],
#main .imod_fieldWrapper .imod_col4 select {
  background: #fff;
  border: 3px solid #CFD82D;
  border-radius: 0;
  box-shadow: none;
  filter: none;
  padding: 0px 10px;
  width: 100%;
  max-width: 100%;
  font-size: 1rem;
}
#main .imod_fieldWrapper .imod_col4 textarea:focus,
#main .imod_fieldWrapper .imod_col4 input[type="text"]:focus,
#main .imod_fieldWrapper .imod_col4 select:focus {
  outline: none;
  border-color: #000;
}
@media only screen and (max-width:  1024px ) {
  #main .imod_fieldWrapper .imod_col4 textarea,
  #main .imod_fieldWrapper .imod_col4 input[type="text"],
  #main .imod_fieldWrapper .imod_col4 select {
    width: 100%;
  }
}
#main .imod_fieldWrapper .imod_col4 input[type="text"] {
  height: 50px;
}
#main .imod_fieldWrapper .imod_col4 select[id*='DropDown1'] {
  height: 50px;
}
#main .imod_fieldWrapper .imod_col4 select[id*='DropDown1'] option {
  height: 50px;
}
#main .imod_fieldWrapper.giftAmountBox {
  background: #f2f2f2;
  border: 1px solid #d9d9d9;
  font-size: 1.5rem;
  padding: 20px;
  margin-bottom: 30px;
}
#main .imod_fieldWrapper.giftAmountBox .imod_fieldLabel {
  width: 100%;
  padding: 0 0 10px;
  line-height: normal;
}
@media only screen and (min-width: 700px ) {
  #main .imod_fieldWrapper.giftAmountBox .imod_fieldLabel {
    line-height: 60px;
    width: auto;
    padding-right: 20px;
    padding-bottom: 0;
  }
}
#main .imod_fieldWrapper.giftAmountBox .imod_fieldInput {
  width: 100%;
  position: relative;
}
#main .imod_fieldWrapper.giftAmountBox .imod_fieldInput span[id*='lblDollarSign'] {
  position: absolute;
  left: 0;
  top: 0;
  line-height: 60px;
  width: 40px;
  font-weight: bold;
  text-align: right;
  padding-right: 5px;
  color: #333;
}
#main .imod_fieldWrapper.giftAmountBox .imod_fieldInput input[type="text"] {
  background: #fff;
  height: 60px;
  line-height: 1em;
  font-size: 35px;
  font-weight: bold;
  padding-left: 40px;
}
@media only screen and (min-width: 700px ) {
  #main .imod_fieldWrapper.giftAmountBox .imod_fieldInput input[type="text"] {
    width: auto;
  }
}
@media only screen and (min-width: 700px ) {
  #main .imod_fieldWrapper.giftAmountBox .imod_fieldInput {
    width: auto;
  }
}
#main .imod_fieldWrapper span[id*="lblFullName2"],
#main .imod_fieldWrapper span[id*='lblCCNoHypens'] {
  display: block;
  font-size: 0.8em;
  font-style: italic;
  opacity: 0.5;
}
#main .imod_fieldWrapper #divPayByCC .imod_fieldWrapper .imod_fieldInput {
  width: auto;
}
#main .imod_fieldWrapper #divPayByCC .imod_fieldWrapper .imod_fieldInput select,
#main .imod_fieldWrapper #divPayByCC .imod_fieldWrapper .imod_fieldInput input {
  width: auto;
}
#main .imod_fieldWrapper .imod-payment-options > div > div > .imod-payment-options-payment-control {
  margin: 0;
}
#main .imod_fieldWrapper .imod-payment-options > div > div > .imod-payment-options-payment-control fieldset {
  margin: 0!important;
  background: #f6f6f6;
  padding: 10px 15px;
  margin: 0;
  border: 2px solid #EBEBEB;
}
@media only screen and (min-width: 700px ) {
  #main .imod_fieldWrapper .imod-payment-options > div > div > .imod-payment-options-payment-control fieldset {
    padding: 20px 30px;
  }
}
#main .imod_fieldWrapper .imod-payment-options > div > div > .imod-payment-options-payment-control legend {
  font-weight: bold;
  font-size: 1.25rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #333;
}
#main .imod_fieldWrapper .imod-payment-options > div > div > .imod-payment-options-payment-control table.imod-payment-table {
  margin: 0!important;
  width: 100%!important;
}
#main .imod_fieldWrapper .imod-payment-options > div > div > .imod-payment-options-payment-control .imod-payment-options-indented-elements {
  margin-left: 0!important;
}
#main .imod_fieldWrapper .imod-payment-options > div > div > .imod-payment-options-payment-control #spPaymentDates,
#main .imod_fieldWrapper .imod-payment-options > div > div > .imod-payment-options-payment-control #spNextPaymentDate {
  font-weight: 900;
  color: #333;
}
#main .designationButtons table[id*="rblAdvancedDesignations"] {
  display: block;
  font-size: 14px;
  line-height: normal;
  margin: 0 -10px;
}
#main .designationButtons table[id*="rblAdvancedDesignations"] tbody {
  display: block;
}
#main .designationButtons table[id*="rblAdvancedDesignations"] tbody tr {
  display: block;
  float: left;
  width: 50%;
  border: 10px solid #fff;
}
@media only screen and (max-width:  1024px ) {
  #main .designationButtons table[id*="rblAdvancedDesignations"] tbody tr {
    width: 100%;
    float: none;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
  }
}
#main .designationButtons table[id*="rblAdvancedDesignations"] tbody tr:nth-child(3n+3) {
  clear: left;
}
#main .designationButtons table[id*="rblAdvancedDesignations"] tbody tr.active td,
#main .designationButtons table[id*="rblAdvancedDesignations"] tbody tr.active td:hover {
  background: #252e13;
  border-color: #607631;
}
#main .designationButtons table[id*="rblAdvancedDesignations"] tbody tr td {
  padding: 0px;
  display: block;
  position: relative;
  transition: all 250ms ease;
}
#main .designationButtons table[id*="rblAdvancedDesignations"] tbody tr td:hover {
  background: #435222;
  cursor: pointer;
}
#main .designationButtons table[id*="rblAdvancedDesignations"] tbody tr td input[type='radio'] {
  position: absolute;
  left: -9999999em;
}
#main .designationButtons table[id*="rblAdvancedDesignations"] tbody tr td input[type='radio']:checked + label {
  background: #00759A;
  color: #fff;
}
#main .designationButtons table[id*="rblAdvancedDesignations"] tbody tr td input[type='radio']:checked + label:before {
  content: "\f058";
  color: #fff;
}
#main .designationButtons table[id*="rblAdvancedDesignations"] tbody tr td label {
  display: block;
  padding: 18px 80px 18px 18px;
  background: #607631;
  border: 2px solid transparent;
  color: #fff;
  font-weight: 900;
  line-height: 1.5em;
  margin: 0;
  font-size: 1rem;
  cursor: pointer;
}
#main .designationButtons table[id*="rblAdvancedDesignations"] tbody tr td label:before {
  color: #ffffff;
  display: block;
  font-family: "Font Awesome 5 Free";
  font-size: 28px;
  font-weight: normal;
  line-height: 30px;
  padding: 0 0px;
  content: "\f111";
  width: 60px;
  text-align: center;
  height: 30px;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: auto;
  bottom: 0;
  right: 0;
  font-weight: bold;
}
#main .designation-section .divAdvancedDesignations {
  width: 100%;
  margin: 0;
  border: 2px solid #d9d9d9;
}
#main .designation-section .divAdvancedDesignations .fa {
  color: #607631;
}
#main .login-password-box {
  width: 100%;
}
#main #divButtons {
  clear: both;
}
#main #divButtons .button {
  background: #CFD82D;
}
#main #divButtons .button:hover,
#main #divButtons .button:focus {
  background: #fff;
}
#main #idbmsStepInstructions + br {
  display: none;
}
.fancybox-overlay .fancybox-wrap {
  width: 600px!important;
  max-width: 90%;
}
.fancybox-overlay .fancybox-wrap .fancybox-inner {
  width: 100%!important;
}
.fancybox-overlay .fancybox-wrap .fancybox-skin {
  padding: 30px!important;
}
@media only screen and (max-width:  1024px ) {
  .fancybox-overlay .fancybox-wrap .fancybox-skin {
    padding: 15px!important;
  }
}
.fancybox-overlay .fancybox-wrap div.imod_designations_wrapper {
  max-width: 100%;
}
.fancybox-overlay .fancybox-wrap div.imod_designations_wrapper br {
  display: none;
}
.fancybox-overlay .fancybox-wrap div.imod_designations_wrapper span.instruction-section {
  font-size: 1em;
  width: 100%;
  margin: 0 0 0px;
}
.fancybox-overlay .fancybox-wrap div.imod_designations_wrapper div.notification-section-top {
  margin-top: 10px;
}
.fancybox-overlay .fancybox-wrap div.header-section {
  float: left;
  min-height: 35px;
  padding: 0 0 15px;
  width: 100%;
}
.fancybox-overlay .fancybox-wrap div.header-section h1 {
  margin: 0;
  padding: 5px 220px 5px 0;
  font-size: 1.5em;
  min-height: 40px;
}
@media only screen and (max-width:  1024px ) {
  .fancybox-overlay .fancybox-wrap div.header-section h1 {
    margin-top: 40px;
    font-size: 1.3em;
    padding-right: 0;
  }
}
.fancybox-overlay .fancybox-wrap div.header-section div.search-section {
  background: #ddd;
  border: 1px solid #ccc;
  margin: 0 !important;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
}
@media only screen and (max-width:  1024px ) {
  .fancybox-overlay .fancybox-wrap div.header-section div.search-section {
    width: 100%;
  }
}
.fancybox-overlay .fancybox-wrap div.header-section div.search-section div.search-box {
  border: none;
  background: none;
}
.fancybox-overlay .fancybox-wrap div#main-section {
  background: #eee;
  border: 2px solid #ddd;
}
.fancybox-overlay .fancybox-wrap div#main-section fieldset {
  border: none;
  margin: 15px 0 0;
  padding: 8px 15px 10px;
}
.fancybox-overlay .fancybox-wrap div#main-section fieldset legend {
  font-size: 1.25em;
  line-height: 1.3em;
}
@media only screen and (max-width:  1024px ) {
  .fancybox-overlay .fancybox-wrap div#main-section fieldset legend {
    font-size: 1em;
  }
}
.fancybox-overlay .fancybox-wrap div#main-section fieldset ul li.section {
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  margin: 0 0 5px;
  width: 100%;
  float: none;
  overflow: hidden;
  position: relative;
}
.fancybox-overlay .fancybox-wrap div#main-section fieldset ul li.section input {
  margin: 6px 5px 0 0;
}
.fancybox-overlay .fancybox-wrap div#main-section fieldset ul li.section label {
  display: block;
  max-width: 100%;
}
.fancybox-overlay .fancybox-wrap div#main-section fieldset ul li.section div.arrow {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  text-align: center;
  padding: 10px;
  line-height: 1;
}
.fancybox-overlay .fancybox-wrap div#main-section fieldset ul li.section ul.sub-section {
  width: 100%;
  float: none;
  margin: 0;
  padding: 5px 0 0 17px;
}
.fancybox-overlay .fancybox-wrap div#main-section fieldset ul li.section ul.sub-section li.child {
  max-width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 5px 15px 5px 0;
}
.fancybox-overlay .fancybox-wrap div#main-section fieldset ul li.section ul.sub-section li.child input[type="checkbox"] {
  width: 15px;
  margin: 0;
}
.fancybox-overlay .fancybox-wrap div#main-section fieldset ul li.section ul.sub-section li.child label {
  float: none;
  padding-left: 23px;
  line-height: normal;
}
.fancybox-overlay .fancybox-wrap div.button-section {
  display: block;
  width: 100%;
  padding: 20px 0 0;
  float: left;
}
.fancybox-overlay .fancybox-wrap div.button-section input {
  background: #607631;
  color: #fff;
  display: inline-block;
  float: right;
  padding: 10px 20px;
  border: none;
  max-width: 40%;
  transition: all 250ms ease;
  margin: 0;
  height: 40px;
}
.fancybox-overlay .fancybox-wrap div.button-section input:hover {
  background: #333;
  color: #fff;
}
.fancybox-overlay .fancybox-wrap div.button-section a {
  border: 1px solid #ccc;
  color: #333;
  margin: 0!important;
  padding: 10px 20px;
  text-decoration: none;
  display: block;
  float: left;
  max-width: 40%;
  line-height: 1;
}
.fancybox-overlay .fancybox-wrap div.button-section a:hover {
  background: #333;
  color: #fff;
  text-decoration: none;
  border-color: #333;
}
.flexbox #ContentMiddle .designationButtons table[id*="rblAdvancedDesignations"] tbody {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width:  1024px ) {
  .flexbox #ContentMiddle .designationButtons table[id*="rblAdvancedDesignations"] tbody {
    display: block;
  }
}
.flexbox #ContentMiddle .designationButtons table[id*="rblAdvancedDesignations"] tbody tr {
  display: inherit;
  flex-direction: column;
}
.flexbox #ContentMiddle .designationButtons table[id*="rblAdvancedDesignations"] tbody tr td {
  flex: 1 0 auto;
}
/* GIVE BUTTONS 1 STYLING 
---------------------------------------------*/
ul.giveBtnsList {
  margin: 0 -10px;
  padding: 0;
  list-style: none;
  text-align: center;
}
ul.giveBtnsList li {
  display: inline-block;
  padding: 0 10px;
  margin-right: -4px;
}
@media only screen and (min-width: 700px) and (max-width:  1024px ) {
  ul.giveBtnsList li {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
@media only screen and (max-width:  1024px ) {
  ul.giveBtnsList li {
    padding: 0 5px;
    width: 50%;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
ul.giveBtnsList li a {
  text-decoration: none;
  display: block;
  font-size: 2em;
  line-height: 1em;
  padding: 20px 30px;
  background: #607631;
  color: #fff!important;
  font-weight: bold;
}
ul.giveBtnsList li a.otherAmt {
  font-weight: normal;
  color: #fff;
}
ul.giveBtnsList li a:hover {
  background: #333;
  color: #fff!important;
  border-color: #000;
}
@media only screen and (min-width: 700px) and (max-width:  1024px ) {
  ul.giveBtnsList li a {
    font-size: 26px;
    padding: 15px 20px;
  }
}
@media only screen and (max-width:  1024px ) {
  ul.giveBtnsList li a {
    font-size: 24px;
    padding: 13px;
  }
}
ul.giveBtnsList span.sm {
  float: left;
  font-size: 0.55em;
  margin-right: 2px;
  position: relative;
  top: -5px;
}
@media only screen and (max-width:  1024px ) {
  ul.giveBtnsList span.sm {
    float: none;
  }
}
/* GIVE BUTTONS 2 STYLING 
---------------------------------------------*/
.giveInputWrapper {
  display: block;
  position: relative;
  height: 50px;
  padding-right: 20px;
  max-width: 50%;
  margin: 0 auto;
}
@media only screen and (min-width: 700px) and (max-width:  1024px ) {
  .giveInputWrapper {
    max-width: 80%;
  }
}
@media only screen and (max-width:  1024px ) {
  .giveInputWrapper {
    max-width: 100%;
  }
}
.giveInputWrapper .giveInputInner {
  background: #fff;
  overflow: hidden;
  padding-left: 45px;
  position: relative;
  border: 2px solid #ccc;
}
.giveInputWrapper label {
  display: none;
}
.giveInputWrapper .dollarSign {
  position: absolute;
  top: 0;
  left: 0;
  color: #333 !important;
  font-size: 25px;
  line-height: 50px;
  width: 35px;
  text-align: right;
}
.giveInputWrapper #giveInput {
  background: none;
  border: none;
  height: 46px;
  color: #333 !important;
  width: 100%;
  font-size: 25px;
}
@media only screen and (max-width:  1024px ) {
  .giveInputWrapper #giveInput {
    font-size: 18px;
  }
}
.giveInputWrapper #giveBtnSubmit {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  border: none;
  background: #333;
  padding: 0 20px;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 250ms ease;
  line-height: 50px;
  text-decoration: none;
  cursor: pointer;
  color: #fff;
}
.giveInputWrapper #giveBtnSubmit:hover {
  background: #000;
}
@media only screen and (max-width:  1024px ) {
  .giveInputWrapper #giveBtnSubmit {
    padding: 0 10px;
    font-size: 11px;
  }
}
.giveInputWrapper #errorMsg {
  font-style: italic;
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
}
.home .sectionFooter {
  margin: 40px auto 0;
  text-align: center;
  display: block;
}
.home .sectionFooter .button {
  margin: 0;
}
.home .greenBar {
  background-color: #607631;
  color: #fff;
  padding: 2rem 0;
  overflow: hidden;
  position: relative;
  font-weight: 500;
}
@media only screen and (min-width: 700px ) {
  .home .greenBar {
    padding: 8.5rem 0;
  }
}
@media only screen and (min-width: 1025px ) {
  .home .greenBar {
    padding: 6rem 1.5rem;
  }
}
.home .greenBar h2 {
  color: #fff;
}
.home .greenBar h3 {
  color: #CFD82D;
  margin-bottom: 1rem;
}
.home .greenBar a {
  color: #fff;
}
.home .greenBar .button {
  color: #000;
  margin-bottom: 0;
}
.home .greenBar .fullImg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
}
.home .greenBar .innerWrap {
  position: relative;
  z-index: 1;
}
.home .greenBar .logo {
  width: 40px;
  position: absolute;
  top: -5px;
  right: 15px;
}
@media only screen and (min-width: 700px ) {
  .home .greenBar .logo {
    top: auto;
    right: auto;
    bottom: 0;
    left: 15px;
    width: 65px;
  }
}
@media only screen and (min-width: 700px ) {
  .home .greenBar .leftCol {
    min-height: 200px;
  }
}
.home .greenBar .rightCol {
  padding-right: 3rem;
}
@media only screen and (min-width: 700px ) {
  .home .greenBar .rightCol {
    padding-right: 3.5rem;
  }
}
/*# sourceMappingURL=.tmp/style.css.map */